import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex align-items-center"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.core)
  }, [
    _createElementVNode("button", {
      class: _normalizeClass([_ctx.$style.toggle, "flex align-items-center ml-4"]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.toggle && $setup.toggle(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item, key) => {
        return (_openBlock(), _createElementBlock("div", { key: key }, [
          (item.key === $setup.locale)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("img", {
                  src: item.icon,
                  alt: "",
                  class: _normalizeClass([_ctx.$style.flag, "mr-3 _img-base"])
                }, null, 10, _hoisted_2),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t(item.label)), 1),
                _createVNode(_component_Icon, {
                  name: "chevron_down",
                  "class-name": "_icon-gray"
                })
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ], 2),
    _createVNode(_component_OverlayPanel, { ref: "panel" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item, key) => {
          return (_openBlock(), _createElementBlock("button", {
            key: key,
            class: "flex align-items-center px-3 py-2",
            onClick: 
          () => {
            $setup.setLocale(key);
            $setup.panel.hide();
          }
        
          }, [
            _createElementVNode("img", {
              src: item.icon,
              alt: "",
              class: _normalizeClass([_ctx.$style.flag, "mr-3 _img-base"])
            }, null, 10, _hoisted_4),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t(item.label)), 1)
          ], 8, _hoisted_3))
        }), 128))
      ]),
      _: 1
    }, 512)
  ], 2))
}