import { Store, useStore } from 'vuex';
import API from '../../api';

export interface CompanyUserStoreState {
  isLoading: boolean;
  isError: boolean;
  isSubmiting: boolean;
  data: {
    id?: any;
    is_active?: boolean;
    credits?: any;
    settings: any;
    company: {
      chief: any;
    };
  };
  error?: any;
}

export const CompanyUserStoreImpl = {
  namespaced: true,

  state: (): CompanyUserStoreState => ({
    isLoading: false,
    isError: false,
    isSubmiting: false,
    data: {
      settings: {},
      company: {
        chief: {}
      }
    }
  }),

  actions: {
    async updateState({ state }: { state: CompanyUserStoreState }, args: { doUpdate: (state: CompanyUserStoreState) => Promise<any> }) {
      return await args.doUpdate(state);
    }
  }
};

export class CompanyUserStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): CompanyUserStoreState {
    return this.store.state.companyUser;
  }

  public dispatch<T>(doUpdate: (state: CompanyUserStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('companyUser/updateState', { doUpdate });
  }

  public async init(user: any) {
    this.dispatch(async (state) => {
      state.isLoading = true;
      try {
        const res = await API.get(`company/users/${user}`);
        state.data = res.data;
        state.isLoading = false;
        state.error = false;
      } catch (error) {
        state.isLoading = false;
        state.error = error;
      }
    });
  }

  public async saveChanges(body, toast, t) {
    this.dispatch(async (state) => {
      state.isSubmiting = true;
      try {
        const res = await API.post(`company/users/${body.user}`, body, 'formData');
        state.data = res.data;
        state.isLoading = false;
        state.isSubmiting = false;
        state.error = false;
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.success_profile'), life: 3000 });
      } catch (error) {
        state.isLoading = false;
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
        state.error = error;
      }
    });
  }

  public async createNew(body, reset, toast, t) {
    this.dispatch(async (state) => {
      state.isSubmiting = true;
      try {
        const res = await API.post('company/users', body);
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.success_profile'), life: 3000 });
        state.data = res.data;
        state.isLoading = false;
        state.isSubmiting = false;
        state.error = false;
        reset();
      } catch (error) {
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
        state.isLoading = false;
        state.isSubmiting = false;
        state.error = error;
      }
    });
  }

  public async toggleBlock() {
    this.dispatch(async (state) => {
      state.isLoading = true;
      try {
        const res = await API.post(`company/users/${state.data.id}/toggle-block`);
        state.isLoading = false;
        state.data.is_active = res.data.is_active;
      } catch (error) {
        state.isLoading = false;
      }
    });
  }

  public async delete() {
    this.dispatch(async (state) => {
      try {
        const res = await API.delete(`company/users/${state.data.id}/toggle-block`);
        state.data.is_active = res.data.is_active;
      } catch (error) {}
    });
  }

  public async addCredits(amount) {
    this.dispatch(async (state) => {
      try {
        await API.post(`company/users/${state.data.id}/add-credits`, { amount });
        state.data.credits = amount;
      } catch (error) {}
    });
  }
}
