
import cookies from 'js-cookie';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import OverlayPanel from 'primevue/overlaypanel';
import { Icon } from '../../components/ui';
import { Avatar } from '../../components/ui';

import API from '../../api';
import { useStores } from '@/store/Stores';

export default {
  components: {
    Avatar,
    Icon,
    OverlayPanel
  },
  setup() {
    const { accountStoreState } = useStores();

    const router = useRouter();

    const items = [
      {
        label: 'logout',
        icon: 'log_out',
        action: () => {
          panel.value.hide();
          API.post('auth/logout');
          cookies.remove('token', '');
          cookies.remove('user', '');
          cookies.remove('no-refresh', '');
          router.push({ name: 'Login' });
        }
      }
    ];
    const panel = ref();
    const toggle = (e) => {
      panel.value.toggle(e);
    };
    return {
      accountStoreState,

      panel,
      items,
      toggle,
      name: computed(() => accountStoreState.data.name)
    };
  }
};
