import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ConfirmPopup = _resolveComponent("ConfirmPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$route.name)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent($options.layout), { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_Toast),
    _createVNode(_component_ConfirmPopup)
  ], 64))
}