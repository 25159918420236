import { Store, useStore } from 'vuex';
import API from '../../api';

export interface CompanyStoreState {
  isLoading: boolean;
  isError: boolean;
  isSubmiting: boolean;
  error?: boolean;
  data: any;
}

export const CompanyStoreImpl = {
  namespaced: true,

  state: (): CompanyStoreState => ({
    isLoading: true,
    isError: false,
    isSubmiting: false,
    data: {}
  }),

  actions: {
    async updateState({ state }: { state: CompanyStoreState }, args: { doUpdate: (state: CompanyStoreState) => Promise<any> }) {
      return await args.doUpdate(state);
    }
  }
};

export class CompanyStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): CompanyStoreState {
    return this.store.state.company;
  }

  public dispatch<T>(doUpdate: (state: CompanyStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('company/updateState', { doUpdate });
  }

  public getData() {
    return this.state.data;
  }

  public setData(data: any) {
    this.dispatch(async (state) => {
      state.data = data;
      state.isLoading = false;
      state.isError = false;
    });
  }

  public init(company: any) {
    this.dispatch(async (state) => {
      state.isLoading = true;
      try {
        const res = await API.get(`admin/companies/${company}`);
        const anglesResponse = await API.get('admin/angles', {
          page: 1,
          per_page: 50
        });

        const angles = anglesResponse.data.data;

        for (let i = 0; i < angles.length; i++) {
          angles[i].index = i + 1;
        }

        const targetAngles: any[] = [];
        const selectedAngleIds: any[] = [];

        for (const selectedAngle of res.data.angles) {
          const srcAngle = angles.filter((s) => s.id == selectedAngle.id)[0];

          if (srcAngle) {
            srcAngle.isSelected = true;

            targetAngles.push(srcAngle);
            selectedAngleIds.push(srcAngle.id);

            srcAngle.show_banner = selectedAngle.show_banner;
            srcAngle.show_logo = selectedAngle.show_logo;
          }
        }

        for (const angle of angles) {
          if (!selectedAngleIds.includes(angle.id)) {
            angle.isSelected = false;

            targetAngles.push(angle);
          }
        }

        let data = {
          ...res.data,
          angles: targetAngles
        };

        if (res.data.chief && res.data.chief.name) {
          data = {
            ...data,
            name: res.data.chief.name,
            email: res.data.chief.email,
            phone: res.data.chief.phone
          };
        }

        delete data.chief;
        state.data = data;
        state.error = false;
        state.isLoading = false;
      } catch (error) {
        state.error = true;
        state.isLoading = false;
      }
    });
  }

  public saveChanges(body, toast, t) {
    this.dispatch(async (state) => {
      state.isSubmiting = true;
      try {
        const selectedAngleIds: any[] = [];
        const logoAngleIds: any[] = [];
        const bannerAngleIds: any[] = [];

        for (let angle of state.data.angles) {
          if (angle.isSelected) {
            selectedAngleIds.push(angle.id);

            if (angle.show_logo) {
              logoAngleIds.push(angle.id);
            }

            if (angle.show_banner) {
              bannerAngleIds.push(angle.id);
            }
          }
        }

        await API.post(`admin/companies/${body.id}`, {
          ...body,
          company: body.id,
          angles: selectedAngleIds,
          logos: logoAngleIds,
          banners: bannerAngleIds
        });

        state.isLoading = false;
        state.isSubmiting = false;
        state.error = false;
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.success_profile'), life: 3000 });
      } catch (error) {
        state.error = true;
        state.isLoading = false;
        state.isSubmiting = false;
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
      }
    });
  }

  public toggleBlock() {
    this.dispatch(async (state) => {
      try {
        const res = await API.post(`admin/companies/${state.data.id}/toggle-block`);
        state.data.is_active = res.data.is_active;
      } catch (error) {
        console.error(error);
      }
    });
  }

  public deleteCompany() {
    this.dispatch(async (state) => {
      try {
        await API.delete(`admin/companies/${state.data.id}/toggle-block`);
      } catch (error) {
        console.error(error);
      }
    });
  }

  public addCredits(amount: any) {
    this.dispatch(async (state) => {
      try {
        const res = await API.post(`admin/companies/${state.data.id}/add-credits`, { amount });
        state.data.credits = res.data.credits;
      } catch (error) {
        console.error(error);
      }
    });
  }

  public addTokens(amount: any, toast, t) {
    this.dispatch(async (state) => {
      try {
        const res = await API.post(`admin/companies/${state.data.id}/add-tokens`, { amount });
        state.data.tokens = res.data.tokens;

        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.tokens_added'), life: 3000 });
      } catch (error) {
        console.error(error);
      }
    });
  }
}
