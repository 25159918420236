import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.core, _ctx.$style[$props.size]])
  }, [
    ($props.img)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: $props.img,
          alt: "",
          class: _normalizeClass(_ctx.$style.img)
        }, null, 10, _hoisted_1))
      : (_openBlock(), _createBlock(_component_Icon, {
          key: 1,
          name: "user-32",
          size: 32
        }))
  ], 2))
}