import { Store, useStore } from 'vuex';
import API from '../../api';
import { AccountStore } from './AccountStore';

export interface RoomComponent {
  base_image_url: string;
  id: number;
  name: string;
  thumbnail_url: string;
  preview: {
    angle_id: number;
    id: number;
    url: string;
  };
}

export interface RoomComponentsStoreState {
  isLoading: boolean;
  isError: boolean;
  isSaving: boolean;

  isDownloading: boolean;
  isUploading: boolean;

  floors: RoomComponent[];
  walls: RoomComponent[];
  rooms: { id: number; floor_id: number; wall_id: number; preview: string }[];

  selectedFloor?: RoomComponent;
  selectedWall?: RoomComponent;

  error: any;
}

export const RoomComponentsStoreImpl = {
  namespaced: true,

  state: (): RoomComponentsStoreState => ({
    isLoading: true,
    isError: false,
    isSaving: false,

    isDownloading: false,
    isUploading: false,

    walls: [],
    floors: [],
    rooms: [],
    error: null as any
  }),

  actions: {
    async updateState({ state }: { state: RoomComponentsStoreState }, args: { doUpdate: (state: RoomComponentsStoreState) => Promise<any> }) {
      return await args.doUpdate(state);
    }
  }
};

export class RoomComponentsStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): RoomComponentsStoreState {
    return this.store.state.roomComponents;
  }

  public dispatch<T>(doUpdate: (state: RoomComponentsStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('roomComponents/updateState', { doUpdate });
  }

  public async init(): Promise<void> {
    const accountStore = new AccountStore();

    this.dispatch(async (state) => {
      state.isLoading = true;

      try {
        if (accountStore.state.data.role === 'chief') {
          const response: any = await API.get('company/room/components');

          state.walls = response.data.walls;
          state.floors = response.data.floors;

          state.selectedWall = state.walls && state.walls.length ? state.walls[0] : (null as any);

          state.selectedFloor = state.floors && state.floors.length ? state.floors[0] : (null as any);
        }

        const response = accountStore.state.data.role === 'chief' ? await API.get('company/room') : await API.get('room');

        state.rooms = response.data;

        state.isLoading = false;
        state.isError = false;
      } catch (error) {
        state.isLoading = false;
        state.isError = true;
        state.error = error;
      }
    });
  }

  public selectFloor(floor: RoomComponent) {
    this.dispatch(async (state) => {
      state.selectedFloor = floor;
    });
  }

  public selectWall(wall: RoomComponent) {
    this.dispatch(async (state) => {
      state.selectedWall = wall;
    });
  }

  public async downloadWallOrFloorImages(target: 'wall' | 'floor') {
    this.dispatch(async (state) => {
      try {
        state.isDownloading = true;

        const targetId = target == 'wall' ? state.selectedWall.id : state.selectedFloor.id;

        let response: any = await API.get(`company/room/${target}/${targetId}/download`);

        const url = response.data.link;

        const a = document.createElement('a');
        a.href = url;
        a.download = url.split('/').pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        state.isDownloading = false;
      } catch (error) {
        state.isError = true;
      }
    });
  }

  public async uploadWallOrFloorImages(target: 'wall' | 'floor', name: string, files: File[]) {
    this.dispatch(async (state) => {
      try {
        state.isUploading = true;

        const targetId = target == 'wall' ? state.selectedWall.id : state.selectedFloor.id;

        const requestParams: any = {
          name: name
        };

        for (let i = 0; i < files.length; i++) {
          requestParams[`image[angle_${i + 1}]`] = files[i];
        }

        await API.post2(`company/room/${target}`, requestParams);

        const componentsResponse: any = await API.get('company/room/components');
        state.walls = componentsResponse.data.walls;
        state.floors = componentsResponse.data.floors;

        state.selectedWall = state.selectedWall ? state.walls.filter((s) => s.id == state.selectedWall.id)[0] : (null as any);

        state.selectedFloor = state.selectedFloor ? state.floors.filter((s) => s.id == state.selectedFloor.id)[0] : (null as any);

        if (!state.selectedWall) {
          state.selectedWall = state.walls && state.walls.length ? state.walls[0] : (null as any);
        }

        if (!state.selectedFloor) {
          state.selectedFloor = state.floors && state.floors.length ? state.floors[0] : (null as any);
        }

        state.isUploading = false;
      } catch (error) {
        state.isUploading = false;
        state.isError = true;
      }
    });
  }
}
