import { errorHandling } from '@/utils';
import { Store, useStore } from 'vuex';
import API from '../../api';

export interface AlbumsStoreState {
  isLoading: boolean;
  isError: boolean;
  data: any;
  error: any;
}

export const AlbumsStoreImpl = {
  namespaced: true,

  state: (): AlbumsStoreState => ({
    isLoading: false,
    isError: false,
    data: null,
    error: null
  }),

  actions: {
    async updateState({ state }: { state: AlbumsStoreState }, args: { doUpdate: (state: AlbumsStoreState) => Promise<any> }) {
      return await args.doUpdate(state);
    }
  }
};

export class AlbumsStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): AlbumsStoreState {
    return this.store.state.albums;
  }

  public dispatch<T>(doUpdate: (state: AlbumsStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('albums/updateState', { doUpdate });
  }

  public setAccount(data: any) {
    this.dispatch(async (state) => (state.data = data));
  }

  private async _init(state: AlbumsStoreState, params: any = null) {
    try {
      state.isLoading = true;
      const res = await API.get('admin/section', params);
      state.data = res.data;
      state.isLoading = false;
      state.isError = false;
      state.error = null;
    } catch (error) {
      state.isLoading = false;
      state.isError = true;
      state.error = error;
    }
  }

  public async init(params: any = null) {
    await this.dispatch(async (state) => this._init(state, params));
  }

  public async saveChanges(body: any, callback: any, toast: any, t: any) {
    await this.dispatch(async (state) => {
      try {
        state.isLoading = true;
        const res: any = await API.post('admin/section', body);
        state.isLoading = false;
        state.isError = false;
        state.error = null;
        if (!res.message) {
          if (body.parent_id) {
            this._init(state, null);
          } else {
            state.data.push(res.data);
          }
          toast.add({ severity: 'success', summary: t('success'), life: 3000 });
        }
        setTimeout(() => {
          callback();
        }, 0);
      } catch (error) {
        state.isLoading = false;
        state.isError = true;
        state.error = error;

        const { title, message } = errorHandling(error, t);
        toast.add({ severity: 'error', summary: title, detail: message, life: 3000 });
      }
    });
  }
}
