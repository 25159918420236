import { LocationQueryValue } from 'vue-router';
import { Store, useStore } from 'vuex';
import API from '../../api';

export interface CarsStoreState {
  isLoading: boolean;
  isError: boolean;
  data: any;
  meta: any;
  error: any;
  carsNeedPremoderation: boolean;
}

export const CarsStoreImpl = {
  namespaced: true,

  state: (): CarsStoreState => ({
    isLoading: true,
    isError: false,
    data: null,
    meta: {},
    error: null,
    carsNeedPremoderation: false
  }),

  actions: {
    async updateState({ state }: { state: CarsStoreState }, args: { doUpdate: (state: CarsStoreState) => Promise<any> }) {
      return await args.doUpdate(state);
    }
  }
};

export class CarsStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): CarsStoreState {
    return this.store.state.cars;
  }

  public dispatch<T>(doUpdate: (state: CarsStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('cars/updateState', { doUpdate });
  }

  public async init(params: any) {
    this.dispatch(async (state) => {
      state.isLoading = true;
      try {
        const res = await API.get('cars', params);

        state.data = res.data.data;
        state.meta = res.data.meta;

        const companyRes = await API.get('auth/me');

        state.carsNeedPremoderation = companyRes.data.cars_need_moderation;

        state.isLoading = false;
        state.isError = false;
      } catch (error) {
        state.isLoading = false;
        state.isError = true;
        state.error = error;
      }
    });
  }
}
