import { LocationQueryValue } from 'vue-router';
import { Store, useStore } from 'vuex';
import { saveAs } from 'file-saver';
import API from '../../api';
import { errorHandling } from '../../utils';
import { AccountStoreState } from './AccountStore';

export interface LicensePlate {
  id: number;
  company_id?: number;
  url: string;
}
export interface AdminCarStoreState {
  isLoading: boolean;
  isError: boolean;
  carID: any;
  isTestRequesting: boolean;
  photoUploading: any;
  data: {
    user_id?: number;
    company?: any;
    car: any;
    vin: any;
    moderated: boolean;
    showToFix?: boolean;
    photos: any[];
    settings: {
      backgrounds: any[];
      logos: any[];
      banners: any[];
      rooms: any[];
      licence_plate?: LicensePlate;
      licence_plate_id?: number;
      licence_plates?: LicensePlate[];
      room_id?: number;
    };
    preset: {
      items: any[];
    };
    angles: any[];
  };
  error: any;
}

export const AdminCarStoreImpl = {
  namespaced: true,

  state: (): AdminCarStoreState => ({
    isLoading: true,
    isError: false,
    carID: null,
    isTestRequesting: false,
    photoUploading: {},
    data: {
      car: null,
      vin: null,
      moderated: false,
      photos: [],
      settings: {
        backgrounds: [],
        banners: [],
        logos: [],
        rooms: []
      },
      preset: {
        items: []
      },
      angles: null
    },
    error: null
  }),

  actions: {
    async updateState({ state }: { state: AdminCarStoreState }, args: { doUpdate: (state: AdminCarStoreState) => Promise<any> }) {
      return await args.doUpdate(state);
    }
  }
};

export class AdminCarStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): AdminCarStoreState {
    return this.store.state.adminCar;
  }

  public dispatch<T>(doUpdate: (state: AdminCarStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('adminCar/updateState', { doUpdate });
  }

  private processingResponses(state, res) {
    if (!res.vehicle_name) {
      res.vehicle_name = `${res.brand} ${res.model}`;
    }
    res.angles.forEach((el) => {
      const photo = res.photos.find(({ angle_id }) => angle_id === el.id);
      if (photo) {
        el.photo_id = photo.id;
        el.photo_url = photo.url;
        el.processed_url = photo.processed_url;
        el.loaded_photo = photo.url;
        el.loaded_photo_processed = photo.processed_url;
        el.in_process = photo.in_process;
        el.status = photo.status;
        el.defect_state = photo.defect_state;
      }
    });

    if (state.data && state.data.angles) {
      let sourceAngles = state.data.angles;
      let resAngles = res.angles;

      sourceAngles.forEach((sourceAngle) => {
        const resAngle = resAngles.filter((s) => s.id == sourceAngle.id)[0];

        if (resAngle && sourceAngle.isSelected) {
          resAngle.isSelected = sourceAngle.isSelected;
        }
      });
    }

    if (state.data && state.data.vehicle_name) {
      res.vehicle_name = state.data.vehicle_name;
    }

    return res;
  }

  public setIsTestRequesting(value: boolean) {
    this.dispatch(async (state) => (state.isTestRequesting = value));
  }

  public resetData() {
    this.dispatch(async (state) => (state.carID = null));
  }

  public getData() {
    return (
      this.state.data[this.state.carID] || {
        photos: [],
        settings: {},
        angles: []
      }
    );
  }

  public withProcessedPhoto() {
    return !!this.state.data.angles.find(({ in_process }) => in_process === 1);
  }

  private async _init(state: AdminCarStoreState, car: any, isTestRequest: boolean) {
    if (state.carID !== car) {
      state.data = {
        car: null,
        vin: null,
        moderated: false,
        photos: [],
        settings: {
          backgrounds: [],
          banners: [],
          logos: [],
          rooms: []
        },
        preset: null,
        angles: []
      };
    }
    state.carID = car;
    if (!isTestRequest) {
      state.isLoading = true;
    }
    try {
      const res = await API.get(`admin/cars/${car}`);

      state.data = this.processingResponses(state, res.data);

      const companiesResponse = await API.get('admin/companies', { per_page: 100 });
      const companies = companiesResponse.data.data;

      state.data.company = companies.filter((s) => s.user_id == state.data.user_id)[0];

      if (!state.data.settings) {
        state.data.settings = {
          backgrounds: [],
          banners: [],
          logos: [],
          rooms: []
        };
      }

      const bgResponse = await API.get(`admin/companies/${state.data.company.id}/background`);
      state.data.settings.backgrounds = bgResponse.data;

      const logoResponse = await API.get(`admin/companies/${state.data.company.id}/logo`);
      state.data.settings.logos = logoResponse.data;

      const bannersResponse = await API.get(`admin/companies/${state.data.company.id}/banner`);
      state.data.settings.banners = bannersResponse.data;

      const roomsResponse = await API.get(`admin/companies/${state.data.company.id}/room`);
      state.data.settings.rooms = roomsResponse.data;

      const platesResponse = await API.get('plate');

      state.data.settings.licence_plates = platesResponse.data;

      state.isError = false;
    } catch (error) {
      state.isError = true;
      state.error = error;
    } finally {
      state.isLoading = false;
    }
  }

  public init(car: any, isTestRequest: boolean = false) {
    this.dispatch(async (state) => await this._init(state, car, isTestRequest));
  }

  public initRooms(companyId: any) {
    this.dispatch(async (state) => {
      const roomsResponse = await API.get(`admin/companies/${companyId}/room`);
      state.data.settings.rooms = roomsResponse.data;
    });
  }

  public saveChanges(params: any, toast: any, t: any) {
    this.dispatch(async (state) => {
      state.isLoading = true;
      const body = {
        car: params.car,
        vin: params.vin,
        company_id: params.company.id,
        vehicle_name: params.vehicle_name,
        brand: params.brand,
        model: params.model,
        body_type: params.body_type,
        fuel_type: params.fuel_type,
        hp: params.hp,
        ccm: params.ccm,
        room_id: params.settings.room_id,
        default_crop: params.settings.default_crop,
        background_id: params.settings.background_id,
        banner_id: params.settings.banner_id,
        background_type: params.settings.background_type,
        logo_id: params.settings.logo_id,
        logo_position: params.settings.logo_position,
        licence_plate_id: params.settings.licence_plate_id,
        cutting: params.settings.cutting,
        plate_blur: params.settings.plate_blur,
        plate_image: params.settings.plate_image,
        polishing: params.settings.polishing,
        rim_polishing: params.settings.rim_polishing,
        reflective_floor: params.settings.reflective_floor
      };

      try {
        const res = await API.post(`admin/cars/${body.car}`, body);

        const backgrounds = state.data.settings.backgrounds;
        const logos = state.data.settings.logos;
        const banners = state.data.settings.banners;
        const rooms = state.data.settings.rooms;
        const licence_plates = state.data.settings.licence_plates;

        state.data = this.processingResponses(state, res.data);

        state.data.settings.backgrounds = backgrounds;
        state.data.settings.logos = logos;
        state.data.settings.banners = banners;
        state.data.settings.rooms = rooms;
        state.data.settings.licence_plates = licence_plates;

        state.isError = false;
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.update_car'), life: 3000 });
      } catch (error) {
        state.isError = true;
        state.error = error;
        const { title, message } = errorHandling(error, t);
        toast.add({ severity: 'error', summary: title, detail: message, life: 3000 });
      } finally {
        state.isLoading = false;
      }
    });
  }

  public uploadPhoto(body: any, toast: any, t: any) {
    this.dispatch(async (state) => {
      try {
        const showToFix = state.data.showToFix;
        state.photoUploading[body.preset_item] = true;
        await API.post(`admin/cars/${body.car}/photo/upload`, body, 'formData');
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.upload_car'), life: 3000 });
        state.photoUploading[body.preset_item] = false;
        await this._init(state, body.car, false);

        const angle = state.data.angles.filter((s) => s.id == body.angle)[0];

        if (angle) {
          angle.defect_state = 'ok';
        }

        state.data.showToFix = showToFix;
      } catch (error) {
        state.photoUploading[body.preset_item] = false;
        console.log(error);
      }
    });
  }

  public uploadPhotoProcessed(body: any, toast: any, t: any) {
    this.dispatch(async (state) => {
      try {
        state.photoUploading[body.preset_item] = true;
        await API.post(`admin/cars/${body.car}/photo/upload-processed`, body, 'formData');
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.upload_car'), life: 3000 });
        state.photoUploading[body.preset_item] = false;
        this._init(state, body.car, false);
      } catch (error) {
        state.photoUploading[body.preset_item] = false;
        console.log(error);
      }
    });
  }

  public downloadPhoto(body: any, toast: any, t: any) {
    this.dispatch(async () => {
      try {
        const res = await API.post(`admin/cars/${body.car}/photo/download`, body);
        saveAs(res.data.link);
      } catch (error) {
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
      }
    });
  }

  public processPhoto(body: any, toast: any, t: any) {
    this.dispatch(async (state) => {
      try {
        await API.post(`admin/cars/${body.car}/photo/process`, body);
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.photo_processing'), life: 3000 });
        state.data.angles.map((item) => {
          if (item.photo_id === body.photo) {
            item.in_process = 1;
          }
        });

        setTimeout(() => {
          this.checkIsProcessed(body.car, body.photo, toast, t);
        }, 2000);
      } catch (error) {
        const { title, message } = errorHandling(error, t);
        toast.add({ severity: 'error', summary: title, detail: message, life: 3000 });
      }
    });
  }

  public async checkIsProcessed(carId: any, photoId: any, toast: any, t: any): Promise<void> {
    this.dispatch(async (state) => {
      await this._checkIsProcessed(state, carId, photoId, toast, t);
    });
  }

  private async _checkIsProcessed(state: AdminCarStoreState, carId: any, photoId: any, toast: any, t: any): Promise<void> {
    if (state.carID != carId) {
      return;
    }

    let isProcessed = false;

    try {
      const res = await API.get(`admin/cars/${carId}`);

      const newData = this.processingResponses(state, res.data);

      const photo = newData.photos.filter((s) => s.id == photoId)[0];

      if (photo) {
        if (photo.status === 4) {
          toast.add({ severity: 'success', summary: t('success'), detail: t('toast.photo_processed'), life: 3000 });

          isProcessed = true;
        }

        this.dispatch(async (state) => {
          const resultPhotos: any[] = [];

          for (const statePhoto of state.data.photos) {
            if (statePhoto.id === photoId) {
              resultPhotos.push(photo);
            } else {
              resultPhotos.push(statePhoto);
            }
          }

          state.data.photos = resultPhotos;

          state.data.angles.map((item) => {
            if (item.photo_id === photoId) {
              item.photo_url = photo.url;
              item.processed_url = photo.processed_url;
              item.loaded_photo = photo.url;
              item.loaded_photo_processed = photo.processed_url;
              item.in_process = photo.in_process;
              item.status = photo.status;
            }
          });
        });
      }
    } catch (error) {}

    if (!isProcessed) {
      setTimeout(() => {
        this.checkIsProcessed(carId, photoId, toast, t);
      }, 2000);
    }
  }

  public approve(car, callback) {
    this.dispatch(async () => {
      try {
        await API.post(`admin/cars/${car}/approve`);
        callback();
      } catch (error) {}
    });
  }
}
