
export default {
  name: 'SvgIcon',

  props: {
    title: String,
    name: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 24
    },
    className: [String, Array]
  },

  computed: {
    icon() {
      let icon = require(`@/assets/icons/${(this as any).size}/${(this as any).name}.svg`);
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default;
      }

      return icon;
    }
  }
};
