import { Store, useStore } from 'vuex';
import { AccountStore, AccountStoreState } from './modules/AccountStore';
import { AdminCarsStore, AdminCarsStoreState } from './modules/AdminCarsStore';
import { AdminCarStore, AdminCarStoreState } from './modules/AdminCarStore';
import { AlbumsStore, AlbumsStoreState } from './modules/AlbumsStore';
import { CarsStore, CarsStoreState } from './modules/CarsStore';
import { CarStore, CarStoreState } from './modules/CarStore';
import { CompaniesStore, CompaniesStoreState } from './modules/CompaniesStore';
import { CompanyExportStore, CompanyExportStoreState } from './modules/CompanyExportStore';
import { CompanyStaffStore, CompanyStaffStoreState } from './modules/CompanyStaffStore';
import { CompanyStaffUserStore, CompanyStaffUserStoreState } from './modules/CompanyStaffUserStore';
import { CompanyStore, CompanyStoreState } from './modules/CompanyStore';
import { CompanyTransactionsStore, CompanyTransactionsStoreState } from './modules/CompanyTransactionsStore';
import { RoomComponentsStore, RoomComponentsStoreState } from './modules/RoomComponentsStore';
import { CompanyTransactionsAdminStore, CompanyTransactionsAdminStoreState } from './modules/CompanyTransactionsAdminStore';
import { CompanyUserStore, CompanyUserStoreState } from './modules/CompanyUserStore';
import { CompanyUsersStore, CompanyUsersStoreState } from './modules/CompanyUsersStore';
import { HeaderStore, HeaderStoreState } from './modules/HeaderStore';
import { PhotosStore, PhotosStoreState } from './modules/PhotosStore';
import { SettingsStore, SettingsStoreState } from './modules/SettingsStore';

export class Stores {
  public static rootStore: Store<any> = null;

  public accountStore: AccountStore;
  public get accountStoreState(): AccountStoreState {
    return this.accountStore.state;
  }

  public albumsStore: AlbumsStore;
  public get albumsStoreState(): AlbumsStoreState {
    return this.albumsStore.state;
  }

  public adminCarStore: AdminCarStore;
  public get adminCarStoreState(): AdminCarStoreState {
    return this.adminCarStore.state;
  }

  public adminCarsStore: AdminCarsStore;
  public get adminCarsStoreState(): AdminCarsStoreState {
    return this.adminCarsStore.state;
  }

  public roomComponentsStore: RoomComponentsStore;
  public get roomComponentsStoreState(): RoomComponentsStoreState {
    return this.roomComponentsStore.state;
  }

  public carStore: CarStore;
  public get carStoreState(): CarStoreState {
    return this.carStore.state;
  }

  public carsStore: CarsStore;
  public get carsStoreState(): CarsStoreState {
    return this.carsStore.state;
  }

  public companiesStore: CompaniesStore;
  public get companiesStoreState(): CompaniesStoreState {
    return this.companiesStore.state;
  }

  public companyStore: CompanyStore;
  public get companyStoreState(): CompanyStoreState {
    return this.companyStore.state;
  }

  public companyTransactionsStore: CompanyTransactionsStore;
  public get companyTransactionsStoreState(): CompanyTransactionsStoreState {
    return this.companyTransactionsStore.state;
  }

  public companyTransactionsAdminStore: CompanyTransactionsAdminStore;
  public get companyTransactionsAdminStoreState(): CompanyTransactionsAdminStoreState {
    return this.companyTransactionsAdminStore.state;
  }

  public companyStaffStore: CompanyStaffStore;
  public get companyStaffStoreState(): CompanyStaffStoreState {
    return this.companyStaffStore.state;
  }

  public companyStaffUserStore: CompanyStaffUserStore;
  public get companyStaffUserStoreState(): CompanyStaffUserStoreState {
    return this.companyStaffUserStore.state;
  }

  public companyExportStore: CompanyExportStore;
  public get companyExportStoreState(): CompanyExportStoreState {
    return this.companyExportStore.state;
  }

  public companyUsersStore: CompanyUsersStore;
  public get companyUsersStoreState(): CompanyUsersStoreState {
    return this.companyUsersStore.state;
  }

  public companyUserStore: CompanyUserStore;
  public get companyUserStoreState(): CompanyUserStoreState {
    return this.companyUserStore.state;
  }

  public headerStore: HeaderStore;
  public get headerStoreState(): HeaderStoreState {
    return this.headerStore.state;
  }

  public photosStore: PhotosStore;
  public get photosStoreState(): PhotosStoreState {
    return this.photosStore.state;
  }

  public settingsStore: SettingsStore;
  public get settingsStoreState(): SettingsStoreState {
    return this.settingsStore.state;
  }

  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    if (store) {
      this.store = store;
    } else if (Stores.rootStore) {
      this.store = Stores.rootStore;
    } else {
      this.store = useStore();
      Stores.rootStore = this.store;
    }

    this.accountStore = new AccountStore(this.store);
    this.albumsStore = new AlbumsStore(this.store);

    this.adminCarStore = new AdminCarStore(this.store);
    this.adminCarsStore = new AdminCarsStore(this.store);

    this.carStore = new CarStore(this.store);
    this.carsStore = new CarsStore(this.store);

    this.companiesStore = new CompaniesStore(this.store);
    this.companyStore = new CompanyStore(this.store);
    this.companyTransactionsStore = new CompanyTransactionsStore(this.store);
    this.companyTransactionsAdminStore = new CompanyTransactionsAdminStore(this.store);
    this.companyStaffStore = new CompanyStaffStore(this.store);
    this.companyStaffUserStore = new CompanyStaffUserStore(this.store);
    this.companyExportStore = new CompanyExportStore(this.store);
    this.companyUserStore = new CompanyUserStore(this.store);
    this.companyUsersStore = new CompanyUsersStore(this.store);

    this.headerStore = new HeaderStore(this.store);

    this.photosStore = new PhotosStore(this.store);

    this.roomComponentsStore = new RoomComponentsStore(this.store);

    this.settingsStore = new SettingsStore(this.store);
  }
}

export function useStores(): Stores {
  return new Stores();
}
