import { Store, useStore } from 'vuex';
import API from '../../api';

export interface CompanyExportStoreState {
  isLoading: boolean;
  isError: boolean;
  isSaving: boolean;

  sendPhotos: boolean;
  saveOriginals: boolean;
  storeAsZip: boolean;

  ftpHost: string;
  ftpUser: string;
  ftpPass: string;
  ftpDir: string;

  dirName: string;
  initialName: string;
  initialIndex: string;

  format: string;

  error: any;
}

export const CompanyExportStoreImpl = {
  namespaced: true,

  state: (): CompanyExportStoreState => ({
    isLoading: true,
    isError: false,
    isSaving: false,

    saveOriginals: false,
    sendPhotos: false,
    storeAsZip: false,

    ftpHost: '',
    ftpUser: '',
    ftpPass: '',
    ftpDir: '',

    dirName: '',
    initialName: '',
    initialIndex: '',

    format: '',

    error: null as any
  }),

  actions: {
    async updateState({ state }: { state: CompanyExportStoreState }, args: { doUpdate: (state: CompanyExportStoreState) => Promise<any> }) {
      return await args.doUpdate(state);
    }
  }
};

export class CompanyExportStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): CompanyExportStoreState {
    return this.store.state.companyExport;
  }

  public dispatch<T>(doUpdate: (state: CompanyExportStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('companyExport/updateState', { doUpdate });
  }

  public async init(): Promise<void> {
    this.dispatch(async (state) => {
      state.isLoading = true;
      try {
        let response: any = await API.get('auth/me');

        state.ftpDir = response.data.company.ftp_dir;
        state.ftpUser = response.data.company.ftp_user;
        state.ftpHost = response.data.company.ftp_host;
        state.ftpPass = '===[password]===';

        state.sendPhotos = response.data.company.send_photos;
        state.saveOriginals = response.data.company.save_originals;
        state.storeAsZip = response.data.company.store_as_zip;

        state.dirName = response.data.company.dir_name;
        state.initialName = response.data.company.initial_name;
        state.initialIndex = response.data.company.initial_index;

        state.format = response.data.company.format;

        state.isLoading = false;
        state.isError = false;
      } catch (error) {
        state.isLoading = false;
        state.isError = true;
        state.error = error;
      }
    });
  }

  public async saveChanges(args: {
    sendPhotos: boolean;
    saveOriginals: boolean;
    storeAsZip: boolean;

    ftpHost: string;
    ftpUser: string;
    ftpPass: string;
    ftpDir: string;

    dirName: string;
    initialName: string;
    initialIndex: string;

    format: string;
  }): Promise<void> {
    this.dispatch(async (state) => {
      try {
        state.isSaving = true;

        let response: any = await API.post('company/profile/ftp', {
          send_photos: args.sendPhotos ? 1 : 0,
          save_originals: args.saveOriginals ? 1 : 0,
          store_as_zip: args.storeAsZip ? 1 : 0,

          ftp_host: args.ftpHost,
          ftp_user: args.ftpUser,
          ftp_pass: args.ftpPass,
          ftp_dir: args.ftpDir,

          dir_name: args.dirName,
          initial_name: args.initialName,
          initial_index: args.initialIndex,

          format: args.format
        });

        response = await API.get('auth/me');

        state.ftpDir = response.data.company.ftp_dir;
        state.ftpUser = response.data.company.ftp_user;
        state.ftpHost = response.data.company.ftp_host;

        state.sendPhotos = response.data.company.send_photos;
        state.saveOriginals = response.data.company.save_originals;
        state.storeAsZip = response.data.company.store_as_zip;

        state.dirName = response.data.company.dir_name;
        state.initialName = response.data.company.initial_name;
        state.initialIndex = response.data.company.initial_index;

        state.format = response.data.company.format;

        state.isSaving = false;
      } catch (error) {
        state.isSaving = false;
        state.isError = true;
      }
    });
  }
}
