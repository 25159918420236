
import cookies from 'js-cookie';
import { Logo, Menu, Header } from './components';

import API from '../api';
import { useStores } from '@/store/Stores';

const getToken = async () => {
  if (!cookies.get('no-refresh') && cookies.get('token')) {
    try {
      const { data } = await API.post('auth/refresh');
      cookies.set('token', data.access_token, { expires: data.expires_in / 8.64e7 });
      cookies.set('no-refresh', true, { expires: data.expires_in / 2 / 8.64e7 });
    } catch (error) {
      console.error(error);
    }
  }
};

export default {
  components: {
    Logo,
    Menu,
    Header
  },
  setup() {
    const { accountStore } = useStores();

    accountStore.setAccount(JSON.parse(cookies.get('user') || '{}'));
    getToken();
    setInterval(() => {
      getToken();
    }, 10000);
  }
};
