import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 3,
  class: "_heading-1"
}
const _hoisted_2 = {
  key: 4,
  class: "_heading-1"
}
const _hoisted_3 = { class: "_container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CarTitle = _resolveComponent("CarTitle")!
  const _component_CarTitleAdmin = _resolveComponent("CarTitleAdmin")!
  const _component_User = _resolveComponent("User")!
  const _component_Language = _resolveComponent("Language")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.header)
  }, [
    _createVNode(_component_Toolbar, {
      class: _normalizeClass(_ctx.$style.container)
    }, {
      left: _withCtx(() => [
        (_ctx.$route.meta.backTo)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: { name: _ctx.$route.meta.backTo },
              class: "p-button p-button-outlined p-button-gray mr-4 p-button-only-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Icon, { name: "arrow_left" })
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true),
        ($setup.headerStoreState.titleComponent === 'CarTitle')
          ? (_openBlock(), _createBlock(_component_CarTitle, { key: 1 }))
          : _createCommentVNode("", true),
        ($setup.headerStoreState.titleComponent === 'CarTitleAdmin')
          ? (_openBlock(), _createBlock(_component_CarTitleAdmin, { key: 2 }))
          : (_ctx.$route.meta.title)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.$t(<any>_ctx.$route.meta.title)), 1))
            : ($setup.headerStoreState.title)
              ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString($setup.headerStoreState.title), 1))
              : _createCommentVNode("", true)
      ]),
      right: _withCtx(() => [
        _createVNode(_component_User),
        _createVNode(_component_Language)
      ]),
      _: 1
    }, 8, ["class"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_TabMenu, {
        model: $setup.headerStoreState.tabs,
        exact: false
      }, {
        item: _withCtx(({ item }) => [
          _withDirectives((_openBlock(), _createBlock(_component_router_link, {
            to: item.to,
            class: "p-menuitem-link"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t((<any>item).label)), 1)
            ]),
            _: 2
          }, 1032, ["to"])), [
            [_directive_t, item.label]
          ])
        ]),
        _: 1
      }, 8, ["model"])
    ])
  ], 2))
}