import axios from 'axios';
import Url from 'url';
import cookies from 'js-cookie';
import getUrlWithParamsConfig from './getUrlWithParamsConfig';

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((error) => {
  return Promise.reject(error.data);
});

const staticHeader = {
  json: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  formData: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
};

function processingBody(headerType, body) {
  switch (headerType) {
    case 'formData':
      const formData = new FormData();
      try {
        Object.entries(body).forEach(([key, value]) => {
          let valueObj: any = value;

          if (key === 'file') {
            valueObj.forEach((item, index) => {
              console.log('item', item);
              return formData.append(key, item);
            });
          } else {
            console.log('value', value);
            formData.append(key, valueObj);
          }
        });
      } catch (error) {
        console.log(error);
      }
      return formData;
    default:
      return JSON.stringify(body);
  }
}

async function customRequest2(method: 'GET' | 'POST' | 'DELETE', path: string, parameters: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    const headers: any = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('token')
    };

    let hasFile = false;

    if (method != 'GET' && parameters) {
      for (const key of Object.keys(parameters)) {
        const value = parameters[key];

        if ('File' in window && value instanceof File) {
          hasFile = true;
        }
      }
    }

    let data: any = null;

    if (hasFile) {
      const formData = new FormData();

      for (const key of Object.keys(parameters)) {
        const value = parameters[key];

        if (value !== null && value !== undefined) {
          formData.append(key, value);
        }
      }

      data = formData;
      headers['Content-Type'] = 'application/x-www-form-urlencoded';
    } else if (method != 'GET') {
      data = {};

      for (const key of Object.keys(parameters)) {
        const value = parameters[key];

        if (value !== null && value !== undefined) {
          data[key] = value;
        }
      }
    }

    axios
      .request({
        method: method,
        url: `https://api.caiman-app.de/api/${path}`,
        data: data,
        headers: headers
      })
      .then((response) => {
        const responseObj: any = response.data;

        if (!responseObj.message) {
          resolve(responseObj);
        } else {
          reject(responseObj);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function customRequest(method, endpoint, params, headerType?, formData?) {
  let config: any = {
    server: {
      host: process.env.VUE_APP_API_HOST,
      protocol: process.env.VUE_APP_API_PROTOCOL
    },
    pathnamePrefix: 'api/'
  };

  let headers = {
    ...staticHeader[headerType || 'json'],
    'Authorization': 'Bearer ' + cookies.get('token')
  };
  let { apiConfigUrl, body } = getUrlWithParamsConfig(method, config, endpoint, params, true);

  switch (method) {
    case 'post':
    case 'put':
    case 'putch':
      return axios.request({
        method: method,
        url: Url.format(apiConfigUrl),
        data: processingBody(headerType, body),
        headers: headers
      });
    default:
      return axios.request({
        method: method,
        url: Url.format(apiConfigUrl),
        headers: headers
      });
  }
}

async function defaultRequest(method, config, endpoint, params, headerType?, formData?) {
  let headers = {
    ...staticHeader[headerType || 'json'],
    'Authorization': 'Bearer ' + cookies.get('token')
  };
  const { apiConfigUrl, body } = getUrlWithParamsConfig(method, config, endpoint, params);
  const options = {
    headers: headers
  };
  switch (method) {
    case 'post':
    case 'put':
    case 'putch':
      return axios[method](Url.format(apiConfigUrl), processingBody(headerType, body), options);
    default:
      return axios[method](Url.format(apiConfigUrl), options);
  }
}

class req {
  get(endpoint, params?, headerType?) {
    return customRequest('get', endpoint, params, headerType);
  }
  post(endpoint, params?, headerType?) {
    return customRequest('post', endpoint, params, headerType);
  }
  delete(endpoint, params?, headerType?) {
    return customRequest('delete', endpoint, params, headerType);
  }
  post2(endpoint, params?) {
    return customRequest2('POST', endpoint, params);
  }
}

export default new req();
