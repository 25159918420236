import cookies from 'js-cookie';
import { Store, useStore } from 'vuex';
import API from '../../api';

export interface AccountSettings {
  banner_id: any;
  default_crop: any;
  background_id: any;
  background_type: any;
  logo_id: any;
  logo_position: any;
  licence_plate_id: any;
  licence_plates?: any[];
  cutting: any;
  plate_blur: any;
  plate_image: any;
  polishing: any;
  rim_polishing: any;
  reflective_floor: any;
  room_id?: number;
  preset_id: any;
  preset: {
    id: any;
    name: any;
  };
  background: {
    id: any;
    url: any;
  };
  logo: {
    id: any;
    url: any;
  };
  licence_plate: {
    id: any;
    url: any;
  };
}

export interface AccountData {
  id: any;
  name: any;
  email: any;
  phone: any;
  avatar: any;
  credits: any;
  role: any;
  tokens: any;
  is_active: any;
  settings: AccountSettings;
  company: any;
}

export interface AccountStoreState {
  isLoading: boolean;
  role: any;
  data: AccountData;
  error: any;
}

export const AccountStoreImpl = {
  namespaced: true,

  state: (): AccountStoreState => ({
    isLoading: false,
    role: null,
    data: {
      id: null,
      name: null,
      email: null,
      phone: null,
      avatar: null,
      role: null,
      credits: null,
      tokens: null,
      is_active: null,
      settings: {
        banner_id: null,
        default_crop: null,
        background_id: null,
        background_type: null,
        logo_id: null,
        logo_position: null,
        licence_plate_id: null,
        cutting: null,
        plate_blur: null,
        plate_image: null,
        polishing: null,
        rim_polishing: null,
        reflective_floor: null,
        preset_id: null,
        preset: {
          id: null,
          name: null
        },
        background: {
          id: null,
          url: null
        },
        logo: {
          id: null,
          url: null
        },
        licence_plate: {
          id: null,
          url: null
        }
      },
      company: null
    },
    error: null
  }),

  actions: {
    async updateState({ state }: { state: AccountStoreState }, args: { doUpdate: (state: AccountStoreState) => Promise<any> }) {
      return await args.doUpdate(state);
    }
  }
};

export class AccountStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): AccountStoreState {
    return this.store.state.account;
  }

  public dispatch<T>(doUpdate: (state: AccountStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('account/updateState', { doUpdate });
  }

  public setAccount(account: any) {
    this.dispatch(async (state) => {
      state.data = account;
      state.role = account.role;
    });
  }

  public async init(): Promise<void> {
    const res = await API.get('auth/me');

    this.setAccount(res.data);

    const isChief = res.data.role === 'chief';

    const platesResponse = await API.get(isChief ? 'company/plate' : 'plate');

    this.dispatch(async (state) => (state.data.settings.licence_plates = platesResponse.data));
  }

  public async saveChanges(body: any, toast: any, t: any) {
    // this.dispatch(async (state) => (state.isLoading = true));

    const isChief = this.state.data.role === 'chief';

    try {
      const res = await API.post(isChief ? 'company/profile' : 'profile', body);
      this.setAccount(res.data);

      toast.add({ severity: 'success', summary: t('success'), detail: t('toast.success_profile'), life: 3000 });
    } catch (error) {
      toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });

      this.dispatch(async (state) => (state.error = error));
    } finally {
      // this.dispatch(async (state) => (state.isLoading = false));
    }
  }
}
