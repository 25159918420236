import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "_container pt-4 pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.core)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.sidebar)
    }, [
      _createVNode(_component_Logo),
      _createVNode(_component_Menu)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.body)
    }, [
      _createVNode(_component_Header),
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 2)
  ], 2))
}