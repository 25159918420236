import cookies from 'js-cookie';
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router';

const commonMenu = [
  {
    icon: 'rim',
    label: 'page.vehicles',
    to: { name: 'Vehicles' }
  },
  {
    icon: 'settings',
    label: 'page.settings',
    to: { name: 'SettingsBasic' }
  },
  {
    icon: 'user',
    label: 'page.profile',
    to: { name: 'Profile' }
  },
  {
    icon: 'chart',
    label: 'page.transactions',
    to: { name: 'Transactions' }
  }
];

export const menu = {
  chief: commonMenu,
  employee: commonMenu.filter(({ label }) => label !== 'page.transactions'),
  photo_manager: [
    {
      icon: 'image',
      label: 'page.photos',
      to: { name: 'PhotoAlbums' }
    }
  ],
  admin: [
    {
      icon: 'briefcase',
      label: 'page.companies',
      to: { name: 'Companies' }
    },
    {
      icon: 'rim',
      label: 'page.vehicles',
      to: { name: 'VehiclesModeration' }
    },
    {
      icon: 'chart',
      label: 'page.transactions',
      to: { name: 'TransactionsAdminCompanies' }
    }
  ]
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      title: 'page.dashboard',
      access: ['chief', 'employee', 'admin']
    }
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: () => import(/* webpackChunkName: "home" */ '../views/Error/AccessDenied.vue'),
    meta: {
      title: 'page.access_denied',
      access: 'auth'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Auth/Login.vue'),
    meta: {
      title: 'page.login',
      layout: 'Auth',
      access: 'public'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "ForgotPassword" */ '../views/Auth/ForgotPassword.vue'),
    meta: {
      title: 'page.forgotPassword',
      layout: 'Auth',
      access: 'public'
    }
  },
  {
    path: '/photos',
    name: 'Photos',
    component: () => import(/* webpackChunkName: "PhotosCore" */ '../views/Photos/index.vue'),
    meta: {
      title: 'page.photos',
      access: 'auth'
    },
    children: [
      {
        path: '',
        name: 'PhotoAlbums',
        component: () => import(/* webpackChunkName: "PhotoAlbums" */ '../views/Photos/PhotoAlbums.vue')
      },
      {
        path: ':photosID',
        name: 'PhotoAlbum',
        component: () => import(/* webpackChunkName: "PhotoAlbum" */ '../views/Photos/PhotoAlbum.vue'),
        meta: {
          backTo: 'PhotoAlbums'
        }
      }
    ]
  },
  {
    path: '/companies',
    component: () => import(/* webpackChunkName: "CompaniesCore" */ '../views/Companies/index.vue'),
    meta: {
      access: ['chief', 'employee', 'admin']
    },
    children: [
      {
        path: '',
        name: 'Companies',
        component: () => import(/* webpackChunkName: "Companies" */ '../views/Companies/Companies.vue'),
        meta: {
          title: 'page.companies'
        }
      },
      {
        path: 'create',
        name: 'CreatingCompany',
        component: () => import(/* webpackChunkName: "CreatingCompany" */ '../views/Companies/Company/AddCompany.vue'),
        meta: {
          backTo: 'Companies',
          title: 'page.creatingCompany'
        }
      },
      {
        path: ':companyID',
        name: 'CompanyCore',
        component: () => import(/* webpackChunkName: "CompanyCore" */ '../views/Companies/Company/index.vue'),
        meta: {
          backTo: 'Companies'
        },
        children: [
          {
            path: 'common',
            name: 'Company',
            component: () => import(/* webpackChunkName: "Company" */ '../views/Companies/Company/Company.vue')
          },

          {
            path: 'staff',
            name: 'CompanyStaffCore',
            component: () => import(/* webpackChunkName: "CompanyStaffCore" */ '../views/Companies/Company/CompanyStaff/index.vue'),
            children: [
              {
                path: '',
                name: 'CompanyStaff',
                component: () => import(/* webpackChunkName: "CompanyStaff" */ '../views/Companies/Company/CompanyStaff/CompanyStaff.vue')
              },
              {
                path: ':companyMemberID',
                name: 'CompanyMember',
                component: () => import(/* webpackChunkName: "CompanyStaff" */ '../views/Companies/Company/CompanyStaff/CompanyMember.vue')
              }
            ]
          },

          {
            path: 'angles',
            name: 'CompanyAngles',
            component: () => import(/* webpackChunkName: "CompanyAngles" */ '../views/Companies/Company/CompanyAngles.vue')
          },

          {
            path: 'scenes',
            name: 'CompanyScenes',
            component: () => import(/* webpackChunkName: "CompanyScenes" */ '../views/Companies/Company/CompanyScenes.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/transactions',
    component: () => import(/* webpackChunkName: "Transactions" */ '../views/Transactions/index.vue'),
    meta: {
      access: ['chief', 'employee'],
      title: 'page.transactions'
    },
    children: [
      {
        path: '',
        name: 'Transactions',
        component: () => import(/* webpackChunkName: "CompanyCore" */ '../views/Transactions/Company.vue')
      }
    ]
  },
  {
    path: '/transactions-admin',
    component: () => import(/* webpackChunkName: "TransactionsAdminCore" */ '../views/TransactionsAdmin/index.vue'),
    meta: {
      access: ['admin']
    },
    children: [
      {
        path: '',
        name: 'TransactionsAdminCompanies',
        component: () => import(/* webpackChunkName: "Companies" */ '../views/TransactionsAdmin/Companies.vue'),
        meta: {
          title: 'page.transactions_by_companies'
        }
      },
      {
        path: ':companyID',
        name: 'TransactionsAdminCompany',
        component: () => import(/* webpackChunkName: "CompanyCore" */ '../views/TransactionsAdmin/Company.vue'),
        meta: {
          backTo: 'TransactionsAdminCompanies'
        }
      }
    ]
  },
  {
    path: '/profile',
    component: () => import(/* webpackChunkName: "ProfileCore" */ '../views/Profile/index.vue'),
    meta: {
      access: ['chief', 'employee']
    },
    children: [
      {
        path: 'common',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "Profile" */ '../views/Profile/Profile.vue'),
        meta: {
          title: 'page.profile'
        }
      },
      {
        path: 'staff',
        name: 'ProfileStaffCore',
        component: () => import(/* webpackChunkName: "ProfileStaffCore" */ '../views/Profile/ProfileStaff/index.vue'),
        meta: {
          title: 'page.staff'
        },
        children: [
          {
            path: '',
            name: 'ProfileStaff',
            component: () => import(/* webpackChunkName: "ProfileStaff" */ '../views/Profile/ProfileStaff/ProfileStaff.vue')
          },
          {
            path: 'create',
            name: 'CreatingEmployee',
            component: () => import(/* webpackChunkName: "CreatingEmployee" */ '../views/Profile/ProfileStaff/CreatingEmployee.vue'),
            meta: {
              title: 'page.creatingProfile',
              backTo: 'ProfileStaff'
            }
          },
          {
            path: ':userID',
            name: 'ProfileStaffPerson',
            component: () => import(/* webpackChunkName: "ProfileStaffPerson" */ '../views/Profile/ProfileStaff/ProfileStaffPerson.vue'),
            meta: {
              backTo: 'ProfileStaff'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/vehicles',
    component: () => import(/* webpackChunkName: "VehiclesCore" */ '../views/Vehicles/index.vue'),
    meta: {
      access: ['chief', 'employee']
    },
    children: [
      {
        path: '',
        name: 'Vehicles',
        component: () => import(/* webpackChunkName: "Vehicles" */ '../views/Vehicles/Vehicles.vue'),
        meta: {
          title: 'page.vehicles'
        }
      },
      {
        path: 'create',
        name: 'CreatingVehicle',
        component: () => import(/* webpackChunkName: "Vehicles" */ '../views/Vehicles/Vehicle/CreatingVehicle.vue'),
        meta: {
          title: 'page.creatingVehicle',
          backTo: 'Vehicles'
        }
      },
      {
        path: ':id',
        name: 'Vehicle',
        component: () => import(/* webpackChunkName: "Vehicles" */ '../views/Vehicles/Vehicle/Vehicle.vue'),
        meta: {
          backTo: 'Vehicles'
        }
      }
    ]
  },
  {
    path: '/vehicles-moderation',
    component: () => import(/* webpackChunkName: "VehiclesCore" */ '../views/Vehicles/index.vue'),
    meta: {
      access: ['admin']
    },
    children: [
      {
        path: '',
        name: 'VehiclesModeration',
        component: () => import(/* webpackChunkName: "Vehicles" */ '../views/VehiclesModeration/VehiclesModeration.vue'),
        meta: {
          title: 'page.vehicles'
        }
      },
      {
        path: ':id',
        name: 'VehicleModeration',
        component: () => import(/* webpackChunkName: "Vehicles" */ '../views/VehiclesModeration/Vehicle/Vehicle.vue'),
        meta: {
          backTo: 'VehiclesModeration'
        }
      }
    ]
  },
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "SettingsCore" */ '../views/Settings/index.vue'),
    meta: {
      title: 'page.settings',
      access: ['chief', 'employee']
    },
    children: [
      {
        path: 'basic',
        name: 'SettingsBasic',
        component: () => import(/* webpackChunkName: "SettingsBasic" */ '../views/Settings/SettingsBasic.vue')
      },
      {
        path: 'backgrounds',
        name: 'SettingsBG',
        component: () => import(/* webpackChunkName: "SettingsBG" */ '../views/Settings/SettingsBG.vue')
      },
      {
        path: 'logos',
        name: 'SettingsLogo',
        component: () => import(/* webpackChunkName: "SettingsLogo" */ '../views/Settings/SettingsLogo.vue')
      },
      {
        path: 'banners',
        name: 'SettingsBanner',
        component: () => import(/* webpackChunkName: "SettingsBanner" */ '../views/Settings/SettingsBanner.vue')
      },
      {
        path: 'export',
        name: 'SettingsExport',
        component: () => import(/* webpackChunkName: "SettingsExport" */ '../views/Settings/SettingsExport.vue')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to: any, from, next) => {
  if (!cookies.get('token')) {
    if (to.meta.access !== 'public') {
      next({ name: 'Login' });
      return;
    }
  } else {
    try {
      const user = JSON.parse(cookies.get('user'));
      if (
        to.meta.access !== 'public' &&
        to.meta.access !== 'auth' &&
        to.name !== 'Login' &&
        to.name !== 'ForgotPassword' &&
        to.meta.access.indexOf(user.role) === -1
      ) {
        next({ name: 'AccessDenied' });
        return;
      }
      next();
    } catch (error) {
      next({ name: 'Login' });
      return;
    }
  }
  next();
});

export default router;
