import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Contenteditable = _resolveComponent("Contenteditable")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Contenteditable, {
        tag: "h1",
        class: "_heading-1",
        contenteditable: true,
        modelValue: $setup.state.vehicle_name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.state.vehicle_name) = $event)),
        noNL: true,
        noHTML: true,
        onReturned: $setup.save
      }, null, 8, ["modelValue", "onReturned"]),
      _createElementVNode("button", {
        class: _normalizeClass([_ctx.$style.action, "ml-3"]),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.save && $setup.save(...args)))
      }, [
        _createVNode(_component_Icon, { name: "save" })
      ], 2)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.vin, "flex"])
    }, [
      _createVNode(_component_Contenteditable, {
        tag: "p",
        class: "_label",
        contenteditable: false,
        modelValue: $setup.state.vin,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.state.vin) = $event)),
        noNL: true,
        noHTML: true,
        onReturned: $setup.save
      }, null, 8, ["modelValue", "onReturned"])
    ], 2)
  ]))
}