import { Store, useStore } from 'vuex';
import API from '../../api';

export interface HeaderStoreState {
  title: any;
  titleComponent: any;
  tabs: any;
}

export const HeaderStoreImpl = {
  namespaced: true,

  state: (): HeaderStoreState => ({
    title: null,
    titleComponent: null,
    tabs: null
  }),

  actions: {
    async updateState({ state }: { state: HeaderStoreState }, args: { doUpdate: (state: HeaderStoreState) => Promise<any> }) {
      return await args.doUpdate(state);
    }
  }
};

export class HeaderStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): HeaderStoreState {
    return this.store.state.header;
  }

  public dispatch<T>(doUpdate: (state: HeaderStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('header/updateState', { doUpdate });
  }

  public setTabs(tabs) {
    this.dispatch(async (state) => (state.tabs = tabs));
  }

  public setTitle(title) {
    this.dispatch(async (state) => (state.title = title));
  }

  public setTitleComponent(titleComponent) {
    this.dispatch(async (state) => {
      state.titleComponent = titleComponent;
      state.title = null;
      state.tabs = null;
    });
  }
}
