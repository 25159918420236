
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import Contenteditable from 'vue-contenteditable';

import { Icon } from '../../components/ui';
import { useStores } from '@/store/Stores';

export default {
  components: {
    Contenteditable,
    Icon
  },
  setup() {
    const { adminCarStoreState, adminCarStore } = useStores();

    const route = useRoute();
    const { t } = useI18n();
    const toast = useToast();
    const state = computed(() => adminCarStoreState.data as any);

    function save() {
      state.value.car = route.params.id;
      adminCarStore.saveChanges(state.value, toast, t);
    }
    return {
      state,
      save
    };
  }
};
