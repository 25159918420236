
import Toolbar from 'primevue/toolbar';
import TabMenu from 'primevue/tabmenu';
import CarTitle from './CarTitle.vue';

import { Icon } from '../../components/ui';

import { default as User } from './User.vue';
import { default as Language } from './Language.vue';
import CarTitleAdmin from './CarTitleAdmin.vue';
import { useStores } from '@/store/Stores';

export default {
  components: {
    TabMenu,
    Toolbar,
    Icon,
    User,
    Language,
    CarTitle,
    CarTitleAdmin
  },
  setup() {
    const { headerStore, headerStoreState } = useStores();

    return { headerStoreState };
  }
};
