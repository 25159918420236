import { Store, useStore } from 'vuex';
import API from '../../api';

export interface CompanyStaffUserStoreState {
  isLoading: boolean;
  isError: boolean;
  isSubmiting: boolean;
  data: any;
  error?: any;
}

export const CompanyStaffUserStoreImpl = {
  namespaced: true,

  state: (): CompanyStaffUserStoreState => ({
    isLoading: true,
    isError: false,
    isSubmiting: false,
    data: {}
  }),

  actions: {
    async updateState({ state }: { state: CompanyStaffUserStoreState }, args: { doUpdate: (state: CompanyStaffUserStoreState) => Promise<any> }) {
      return await args.doUpdate(state);
    }
  }
};

export class CompanyStaffUserStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): CompanyStaffUserStoreState {
    return this.store.state.companyStaffUser;
  }

  public dispatch<T>(doUpdate: (state: CompanyStaffUserStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('companyStaffUser/updateState', { doUpdate });
  }

  public async init(user: any) {
    this.dispatch(async (state) => {
      state.isLoading = true;
      try {
        const res = await API.get(`admin/users/${user}`);
        state.data = res.data;
        state.error = false;
        state.isLoading = false;
      } catch (error) {
        state.error = true;
        state.isLoading = false;
      }
    });
  }

  public async saveChanges(body, toast, t) {
    this.dispatch(async (state) => {
      state.isSubmiting = true;
      try {
        const res = await API.post(`admin/users/${body.id}`, body);
        const data = {
          ...res.data
        };
        delete data.chief;
        state.isLoading = false;
        state.isSubmiting = false;
        state.data = data;
        state.error = false;
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.success_profile'), life: 3000 });
      } catch (error) {
        state.error = true;
        state.isLoading = false;
        state.isSubmiting = false;
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
      }
    });
  }

  public async toggleBlock() {
    this.dispatch(async (state) => {
      try {
        const res = await API.post(`admin/users/${state.data.id}/toggle-block`);
        state.data.is_active = res.data.is_active;
      } catch (error) {
        console.error(error);
      }
    });
  }
}
