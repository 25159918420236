import { Store, useStore } from 'vuex';
import API from '../../api';

export interface CompanyTransactionsStoreState {
  isLoading: boolean;
  isError: boolean;
  isDownloading: boolean;

  data: {
    company: any;
    transactions: any[];
  };
  meta: any;
  error?: any;
}

export const CompanyTransactionsStoreImpl = {
  namespaced: true,

  state: (): CompanyTransactionsStoreState => ({
    isLoading: false,
    isError: false,
    isDownloading: false,

    data: {
      company: {},
      transactions: []
    },
    meta: {}
  }),

  actions: {
    async updateState(
      { state }: { state: CompanyTransactionsStoreState },
      args: { doUpdate: (state: CompanyTransactionsStoreState) => Promise<any> }
    ) {
      return await args.doUpdate(state);
    }
  }
};

export class CompanyTransactionsStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): CompanyTransactionsStoreState {
    return this.store.state.companyTransactions;
  }

  public dispatch<T>(doUpdate: (state: CompanyTransactionsStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('companyTransactions/updateState', { doUpdate });
  }

  public async init(params: any) {
    this.dispatch(async (state) => {
      state.isLoading = true;
      try {
        const companyRes = await API.get('auth/me');

        const res = await API.get('company/transactions', params);

        const data = {
          company: companyRes.data.company,
          transactions: res.data.data
        };

        for (const transaction of data.transactions) {
          if (transaction.car_id) {
            const carResponse = await API.get(`cars/${transaction.car_id}`);

            transaction.car = carResponse.data;
          }
        }

        state.isLoading = false;
        state.data = data;
        state.meta = res.data.meta;
        state.isError = false;
        state.error = null;
      } catch (error) {
        state.isLoading = false;
        state.isError = true;
        state.error = error;
      }
    });
  }

  public async download(params: any) {
    this.dispatch(async (state) => {
      state.isDownloading = true;

      const res = await API.get('company/transactions/download', params);

      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data));
      element.setAttribute('download', `export-${params.date_from}-${params.date_to}.csv`);

      element.style.display = 'none';
      document.body.appendChild(element);

      state.isDownloading = false;
      element.click();

      document.body.removeChild(element);
    });
  }
}
