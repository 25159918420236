
import ProgressSpinner from 'primevue/progressspinner';
export default {
  components: {
    ProgressSpinner
  },
  props: {
    isOverlay: Boolean
  },
  setup() {
    return {};
  }
};
