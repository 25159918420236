export default {
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "save_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "replay_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replay new password"])},
  "something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong "])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block"])},
  "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock"])},
  "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock"])},
  "password_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password mismatch "])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After"])},
  "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before"])},
  "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to proceed?"])},
  "confirm_approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to approve this vehicle?"])},
  "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
  "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no data"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
  "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access denied"])},
  "available_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available options"])},
  "bg_constructor": {
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploading..."])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving..."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "wall_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wall name:"])},
    "floor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor name:"])},
    "wall_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wall images"])},
    "floor_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor images"])},
    "no_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No file"])},
    "set_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set file"])},
    "replace_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace file"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["downloading..."])}
  },
  "scene_constructor": {
    "add_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add photobox"])},
    "constructed_scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual showrooms"])},
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
    "select_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select color"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving..."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "upload_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload new"])},
    "zero_layer_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unicolor"])},
    "own_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own backround"])},
    "construct_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construct background"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new"])},
    "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo box"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
    "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner"])}
  },
  "toast": {
    "success_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile updated"])},
    "tokens_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens added"])},
    "vehicle_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle added"])},
    "vehicle_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Released"])},
    "photo_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo has been sent for processing"])},
    "photo_processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo has been processed successfully"])},
    "update_car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle data updated"])},
    "upload_car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle image loaded"])},
    "no_files_to_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No files to download"])}
  },
  "page": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
    "transactions_by_companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions by companies"])},
    "creatingCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New company"])},
    "vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicles"])},
    "creatingVehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New vehicle"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "creatingProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New User"])},
    "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photos"])},
    "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Denied"])}
  },
  "tabs": {
    "company_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "angles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Views"])},
    "settings_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic settings"])},
    "settings_bg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo boxes"])},
    "settings_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logos"])},
    "settings_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banners"])},
    "settings_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
  },
  "language": {
    "en_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "de_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
    "pt_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
    "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pt"])}
  },
  "status_bool": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked"])}
  },
  "profile": {
    "total_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credits"])},
    "available_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Tokens"])},
    "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload new photo"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact phone"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "add_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
    "this_is_your_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is your login"])},
    "password_min_length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Password must be minimum ", _interpolate(_named("number")), " characters"])},
    "password_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password and Confirm Password should match"])}
  },
  "login_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAIMAN Dashboard"])},
    "sub_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi there! Nice to see you again."])},
    "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "contact_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact support"])},
    "restore_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore Password"])},
    "remembered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I remembered the password!"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
    "restore_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not worry! Just enter your E-mail"])},
    "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User with such data does not exist"])}
  },
  "company_page": {
    "add_new_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new company"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
    "contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credits"])},
    "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "available_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available tokens"])},
    "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens"])},
    "add_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add company"])},
    "additional_uploads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional uploads"])},
    "attention_for_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention! A new password will be sent to the above email!"])},
    "add_new_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new user"])},
    "can_work_on_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can work on debt"])},
    "premoderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality check!"])},
    "show_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show logo"])},
    "show_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show banner"])},
    "allowed_angles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select allowed views"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
  },
  "transactions_page": {
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
    "vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle"])}
  },
  "vehicles_page": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "add_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Vehicle"])},
    "add_vehicle_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add more"])},
    "vin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vin"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
    "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
    "contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person"])},
    "fuel_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel Type"])},
    "on_moderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For quality check"])},
    "hp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hp"])},
    "ccm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCM"])},
    "vehicle_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle name"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
    "savePhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Photo"])},
    "replacePhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace Photo"])},
    "uploadPhotoProcessed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload processed"])},
    "replacePhotoProcessed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace processed"])},
    "deleteVehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Vehicle"])},
    "body_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body type"])},
    "modal_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention! You can move the background for better positioning of your car"])},
    "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload photo"])},
    "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No name"])},
    "remove_extra_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove extra photo"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
    "with_original_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With original images"])},
    "with_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With banner"])},
    "extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
    "moderated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality check!"])},
    "not_moderated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["..."])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "for_premoderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality check"])},
    "to_fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correct"])},
    "all_companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All companies"])},
    "find_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find company"])},
    "select_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select company"])},
    "approve_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
    "vehicle_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
    "show_to_fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show to correct"])},
    "hide_to_fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])}
  },
  "scenes": {
    "add_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add photo box"])}
  },
  "settings_page": {
    "tooltip_cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle is cut out and placed in your fotobox. This function must be activated"])},
    "tooltip_plate_blur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License plate is anonymized by blurring"])},
    "tooltip_plate_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car license plate is replaced by a graphic"])},
    "default_crop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default crop"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base"])},
    "photo_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo box"])},
    "default_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default scene"])},
    "default_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default logo"])},
    "default_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default banner"])},
    "default_preset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default preset"])},
    "car_crop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crop for current vehicle"])},
    "car_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo box for current vehicle"])},
    "car_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner for current vehicle"])},
    "car_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner for current vehicle"])},
    "car_preset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset for current vehicle"])},
    "background_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background type"])},
    "logo_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo position"])},
    "license_plate_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License plate image"])},
    "cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cutting"])},
    "licence_plate_blur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence plate blur"])},
    "licence_plate_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence plate image"])},
    "demo_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demonstration of the processing result"])},
    "add_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add logo"])},
    "export": {
      "ftp_host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ftp Host"])},
      "ftp_directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ftp Directory"])},
      "ftp_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ftp User"])},
      "ftp_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ftp Password"])},
      "directory_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directory name"])},
      "initial_file_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial file name"])},
      "initial_file_index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial file index"])},
      "file_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File format"])},
      "send_photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send photos"])},
      "save_originals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save originals"])},
      "store_as_zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store as zip"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "exporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporting..."])}
    },
    "title": {
      "preset_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset backgrounds"])},
      "own_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own backgrounds"])},
      "constructed_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Constructed photo boxes"])},
      "scene_constructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Constructed photo boxes"])},
      "logotype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own logotypes"])},
      "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own banners"])}
    }
  },
  "photos_page": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "added_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added section"])},
    "all_photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All photos"])},
    "all_processed_photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All processed photos"])},
    "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photos"])},
    "processed_photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed photos"])},
    "markup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markup"])},
    "upload_markup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload markup"])},
    "selected_to_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select to download"])},
    "processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])}
  },
  "paginator": {
    "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing ", _interpolate(_named("count")), " out of ", _interpolate(_named("total"))])},
    "separator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])}
  }
}