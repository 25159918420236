
import { ref } from 'vue';
import { Icon } from '../../components/ui';
import OverlayPanel from 'primevue/overlaypanel';
import iconEn from '../../assets/img/flag/uk.svg';
import iconDe from '../../assets/img/flag/germany.svg';
import i18n from '@/i18n';
import cookies from 'js-cookie';

// $i18n.locale
export default {
  components: {
    Icon,
    OverlayPanel
  },
  setup() {
    const items = {
      'en': {
        label: 'language.en',
        icon: iconEn,
        key: 'en'
      },
      'de': {
        label: 'language.de',
        icon: iconDe,
        key: 'de'
      }
    };
    const panel = ref();
    const toggle = (e) => {
      panel.value.toggle(e);
    };

    let locale = i18n.global.locale;

    const setLocale = (value) => {
      locale = value;
      i18n.global.locale.value = value;

      cookies.set('locale', value, { expires: 860000 });
    };

    return { items, toggle, panel, locale, setLocale };
  }
};
