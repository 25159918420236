
import { computed } from 'vue';
import { Icon } from '../../components/ui';
import { menu } from '../../router';
import { useStores } from '@/store/Stores';

export default {
  components: {
    Icon
  },
  setup() {
    const { accountStoreState } = useStores();

    return {
      menu: computed(() => menu[accountStoreState.role])
    };
  }
};
