import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ml-3" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.core, "flex align-items-center"]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.toggle && $setup.toggle(...args)))
    }, [
      _createVNode(_component_Avatar, {
        size: "medium",
        img: $setup.accountStoreState.data.avatar
      }, null, 8, ["img"]),
      _createElementVNode("span", _hoisted_1, _toDisplayString($setup.name), 1),
      _createVNode(_component_Icon, {
        name: "chevron_down",
        className: "_icon-gray"
      })
    ], 2),
    _createVNode(_component_OverlayPanel, { ref: "panel" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item, key) => {
          return (_openBlock(), _createElementBlock("button", {
            class: "flex align-items-center px-3 py-2",
            key: key,
            onClick: item.action
          }, [
            _createVNode(_component_Icon, {
              name: item.icon,
              className: "_icon-gray"
            }, null, 8, ["name"]),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(item.label)), 1)
          ], 8, _hoisted_2))
        }), 128))
      ]),
      _: 1
    }, 512)
  ], 64))
}