import { errorHandling } from '@/utils';
import cookies from 'js-cookie';
import { saveAs } from 'file-saver';
import { Store, useStore } from 'vuex';
import API from '../../api';

import { LicensePlate } from './AdminCarStore';

export interface CarStoreState {
  isLoading: boolean;
  isError: boolean;
  carID: any;
  isTestRequesting: boolean;
  photoUploading: any;
  data: {
    angles?: any[];

    car?: any;
    vin?: any;
    moderated?: boolean;
    carsNeedPremoderation?: boolean;
    photos?: any[];
    settings?: {
      backgrounds: any[];
      logos: any[];
      banners: any[];
      rooms: any[];
      licence_plate?: LicensePlate;
      licence_plate_id?: number;
      licence_plates?: LicensePlate[];
      plate_image?: boolean;
      room_id?: number;
      banner_id?: number;
      logo_id?: number;
      background_type?: string;
      background_id?: number;
      plate_blur?: boolean;
    };
    preset?: {
      items: any[];
    };
    value?: any;

    extra_photos?: {
      car_id: number;
      created_at: number;
      id: number;
      thumbnail: string;
      url: string;
      isExtra: boolean;
    }[];
  };
  error: any;
}

export const CarStoreImpl = {
  namespaced: true,

  state: (): CarStoreState => ({
    isLoading: true,
    isError: false,
    carID: null,
    isTestRequesting: false,
    photoUploading: {},
    data: {
      angles: [],
      car: null,
      vin: null,
      moderated: false,
      carsNeedPremoderation: false,
      photos: [],
      settings: {} as any,
      preset: {
        items: []
      }
    },
    error: null
  }),

  actions: {
    async updateState({ state }: { state: CarStoreState }, args: { doUpdate: (state: CarStoreState) => Promise<any> }) {
      return await args.doUpdate(state);
    }
  }
};

export class CarStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): CarStoreState {
    return this.store.state.car;
  }

  public dispatch<T>(doUpdate: (state: CarStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('car/updateState', { doUpdate });
  }

  private processingResponses(state, res) {
    if (!res.vehicle_name) {
      res.vehicle_name = `${res.brand} ${res.model}`;
    }
    res.angles.forEach((el) => {
      const photo = res.photos.find(({ angle_id }) => angle_id === el.id);
      if (photo) {
        el.photo_id = photo.id;
        el.photo_url = photo.url;
        el.processed_url = photo.processed_url;
        el.loaded_photo = photo.url;
        el.loaded_photo_processed = photo.processed_url;
        el.in_process = photo.in_process;
        el.status = photo.status;
      }
    });

    if (state.data && state.data.angles) {
      let sourceAngles = state.data.angles;
      let resAngles = res.angles;

      sourceAngles.forEach((sourceAngle) => {
        const resAngle = resAngles.filter((s) => s.id == sourceAngle.id)[0];

        if (resAngle && sourceAngle.isSelected) {
          resAngle.isSelected = sourceAngle.isSelected;
        }
      });
    }

    if (state.data && state.data.vehicle_name) {
      res.vehicle_name = state.data.vehicle_name;
    }

    return res;
  }

  public setIsTestRequesting(value: boolean) {
    this.dispatch(async (state) => (state.isTestRequesting = value));
  }

  public resetData() {
    this.dispatch(async (state) => (state.carID = null));
  }

  public getData() {
    return (
      this.state.data[this.state.carID] || {
        photos: [],
        settings: {},
        angles: []
      }
    );
  }

  public withProcessedPhoto() {
    return !!this.state.data.angles.find(({ in_process }) => in_process === 1);
  }

  private async _init(state: CarStoreState, car: any, isTestRequest: boolean = false) {
    if (state.carID !== car) {
      state.data = {
        photos: [],
        settings: {} as any,
        angles: []
      };
    }
    state.carID = car;
    if (!isTestRequest) {
      state.isLoading = true;
    }
    try {
      const res = await API.get(`cars/${car}`);

      state.data = this.processingResponses(state, res.data);
      state.isError = false;
    } catch (error) {
      state.isError = true;
      state.error = error;
    } finally {
      state.isLoading = false;
    }

    const res = await API.get('auth/me');

    state.data.carsNeedPremoderation = !!res.data.cars_need_moderation;

    if (state.data.extra_photos) {
      for (const extraPhoto of state.data.extra_photos) {
        extraPhoto.isExtra = true;
      }
    }

    const isChief = res.data.role === 'chief';

    const platesResponse = await API.get(isChief ? 'company/plate' : 'plate');

    state.data.settings.licence_plates = platesResponse.data;
  }

  public init(car: any, isTestRequest: boolean = false) {
    this.dispatch(async (state) => await this._init(state, car, isTestRequest));
  }

  public saveChanges(params: any, toast: any, t: any) {
    this.dispatch(async (state) => {
      state.isLoading = true;
      const body = {
        car: params.car,
        vin: params.vin,
        vehicle_name: params.vehicle_name,
        brand: params.brand,
        model: params.model,
        body_type: params.body_type,
        fuel_type: params.fuel_type,
        hp: params.hp,
        ccm: params.ccm,
        default_crop: params.settings.default_crop,
        background_id: params.settings.background_id,
        banner_id: params.settings.banner_id,
        background_type: params.settings.background_type,
        logo_id: params.settings.logo_id,
        logo_position: params.settings.logo_position,
        room_id: params.settings.room_id,
        licence_plate_id: params.settings.licence_plate_id,
        cutting: params.settings.cutting,
        plate_blur: params.settings.plate_blur,
        plate_image: params.settings.plate_image,
        polishing: params.settings.polishing,
        rim_polishing: params.settings.rim_polishing,
        reflective_floor: params.settings.reflective_floor
      };

      try {
        const res = await API.post(`cars/${body.car}`, body);
        state.data = this.processingResponses(state, res.data);
        state.isError = false;
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.update_car'), life: 3000 });
      } catch (error) {
        state.isError = true;
        state.error = error;
        const { title, message } = errorHandling(error, t);
        toast.add({ severity: 'error', summary: title, detail: message, life: 3000 });
      } finally {
        state.isLoading = false;
      }
    });
  }

  public uploadExtraPhoto(body: any, toast: any, t: any) {
    this.dispatch(async (state) => {
      try {
        state.photoUploading[body.preset_item] = true;
        await API.post(`cars/${body.car}/photo/extra/upload`, body, 'formData');
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.upload_car'), life: 3000 });
        state.photoUploading[body.preset_item] = false;
        this._init(state, body.car);
      } catch (error) {
        state.photoUploading[body.preset_item] = false;
        console.log(error);
      }
    });
  }

  public removeExtraPhoto(body: any, toast: any, t: any) {
    this.dispatch(async (state) => {
      try {
        await API.post(`cars/${body.car}/photo/extra/delete`, body);
        toast.add({ severity: 'success', summary: t('success'), detail: 'Extra photo removed', life: 3000 });
        this._init(state, body.car);
      } catch (error) {
        state.photoUploading[body.preset_item] = false;
        console.log(error);
      }
    });
  }

  public uploadPhoto(body: any, toast: any, t: any) {
    this.dispatch(async (state) => {
      try {
        state.photoUploading[body.preset_item] = true;
        await API.post(`cars/${body.car}/photo/upload`, body, 'formData');
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.upload_car'), life: 3000 });
        state.photoUploading[body.preset_item] = false;
        this._init(state, body.car);
      } catch (error) {
        state.photoUploading[body.preset_item] = false;
        console.log(error);
      }
    });
  }

  public downloadPhoto(body: any, toast: any, t: any) {
    this.dispatch(async () => {
      try {
        const res = await API.post(`company/cars/${body.car}/photo/download`, body);
        saveAs(res.data.link);
      } catch (error) {
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
      }
    });
  }

  public processPhoto(body: any, toast: any, t: any) {
    this.dispatch(async (state) => {
      try {
        await API.post(`company/cars/${body.car}/photo/process`, body);
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.photo_processing'), life: 3000 });
        state.data.angles.map((item) => {
          if (item.photo_id === body.photo) {
            item.in_process = 1;
          }
        });

        setTimeout(() => {
          this.checkIsProcessed(body.car, body.photo, toast, t);
        }, 2000);
      } catch (error) {
        const { title, message } = errorHandling(error, t);
        toast.add({ severity: 'error', summary: title, detail: message, life: 3000 });
      }
    });
  }

  public async checkIsProcessed(carId: any, photoId: any, toast: any, t: any): Promise<void> {
    this.dispatch(async (state) => {
      await this._checkIsProcessed(state, carId, photoId, toast, t);
    });
  }

  private async _checkIsProcessed(state: CarStoreState, carId: any, photoId: any, toast: any, t: any): Promise<void> {
    if (state.carID != carId) {
      return;
    }

    let isProcessed = false;

    try {
      const res = await API.get(`cars/${carId}`);

      const newData = this.processingResponses(state, res.data);

      const photo = newData.photos.filter((s) => s.id == photoId)[0];

      if (photo) {
        if (photo.status === 4) {
          toast.add({ severity: 'success', summary: t('success'), detail: t('toast.photo_processed'), life: 3000 });

          isProcessed = true;
        }

        this.dispatch(async (state) => {
          const resultPhotos: any[] = [];

          for (const statePhoto of state.data.photos) {
            if (statePhoto.id === photoId) {
              resultPhotos.push(photo);
            } else {
              resultPhotos.push(statePhoto);
            }
          }

          state.data.photos = resultPhotos;

          state.data.angles.map((item) => {
            if (item.photo_id === photoId) {
              item.photo_url = photo.url;
              item.processed_url = photo.processed_url;
              item.loaded_photo = photo.url;
              item.loaded_photo_processed = photo.processed_url;
              item.in_process = photo.in_process;
              item.status = photo.status;
            }
          });
        });
      }
    } catch (error) {}

    if (!isProcessed) {
      setTimeout(() => {
        this.checkIsProcessed(carId, photoId, toast, t);
      }, 2000);
    }
  }

  public async deleteCar(car, callback) {
    try {
      await API.delete(`cars/${car}/delete`);
      callback();
    } catch (error) {}
  }
}
