import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!

  return (_openBlock(), _createBlock(_component_Tag, {
    severity: $setup.status.type
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t($setup.status.label)), 1)
    ]),
    _: 1
  }, 8, ["severity"]))
}