import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["p-5 flex flex-column align-items-center justify-content-center w-full", $props.isOverlay && _ctx.$style.overlay])
  }, [
    _createVNode(_component_ProgressSpinner)
  ], 2))
}