export const errorHandling = (
  error: any,
  t
): {
  code: string;
  title: string;
  message: string;
  fields: string[];
} => {
  if (!error || !error.response) {
    return {
      code: '',
      title: t('something_went_wrong'),
      message: '',
      fields: []
    };
  } else {
    let errors: any = error.response && error.response.data && error.response.data.errors ? error.response.data.errors : {};

    return {
      code: error.response.status,
      title: error.response.data.message,
      message: error.response.data.errors,
      fields: Object.keys(errors)
    };
  }
};
