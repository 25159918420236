
import * as layouts from './layouts';
import Toast from 'primevue/toast';
import ConfirmPopup from 'primevue/confirmpopup';

document.title = 'Caiman';

export default {
  components: {
    Toast,
    ConfirmPopup
  },
  computed: {
    layout() {
      return layouts[(this as any).$route.meta.layout] || layouts.Default;
    }
  }
};
