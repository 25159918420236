import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["viewBox", "width", "height"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass($props.className),
    viewBox: $options.icon.viewBox,
    width: $props.size,
    height: $props.size,
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    ($props.title)
      ? (_openBlock(), _createElementBlock("title", _hoisted_2, _toDisplayString($props.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("use", {
      "xlink:href": $options.icon.url,
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }, null, 8, _hoisted_3)
  ], 10, _hoisted_1))
}