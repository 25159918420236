
import { ref } from 'vue';
import { Icon } from './';
export default {
  components: {
    Icon
  },
  props: {
    size: String,
    img: String
  },
  setup() {
    return {};
  }
};
