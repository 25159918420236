import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menu, (item) => {
    return (_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(_ctx.$style.item),
      key: item.url || item.to.name
    }, [
      (item.to)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: item.to,
            class: _normalizeClass(_ctx.$style.link),
            "active-class": _ctx.$style.active
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Icon, {
                name: item.icon
              }, null, 8, ["name"]),
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.$style.name)
              }, _toDisplayString(_ctx.$t(item.label)), 3)
            ]),
            _: 2
          }, 1032, ["to", "class", "active-class"]))
        : _createCommentVNode("", true)
    ], 2))
  }), 128))
}