
import { ref } from 'vue';
import Icon from './Icon.vue';

export default {
  components: {
    Icon
  },
  props: {
    url: String,
    col: String,
    callback: Function
  },
  setup() {
    return {};
  }
};
