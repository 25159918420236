
import Toolbar from 'primevue/toolbar';
import { Language } from './components';

import imgCar from './img/car.png';
import imgKey from './img/key.png';

export default {
  components: {
    Toolbar,
    Language
  },
  setup() {
    return {
      imgs: {
        'Login': imgCar,
        'ForgotPassword': imgKey
      }
    };
  }
};
