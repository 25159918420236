import { Store, useStore } from 'vuex';
import API from '../../api';

export interface CompaniesStoreState {
  isLoading: boolean;
  isError: boolean;
  data: any;
  meta: any;
  error?: any;
}

export const CompaniesStoreImpl = {
  namespaced: true,

  state: (): CompaniesStoreState => ({
    isLoading: false,
    isError: false,
    data: null,
    meta: {}
  }),

  actions: {
    async updateState({ state }: { state: CompaniesStoreState }, args: { doUpdate: (state: CompaniesStoreState) => Promise<any> }) {
      return await args.doUpdate(state);
    }
  }
};

export class CompaniesStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): CompaniesStoreState {
    return this.store.state.companies;
  }

  public dispatch<T>(doUpdate: (state: CompaniesStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('companies/updateState', { doUpdate });
  }

  public async init(params: any) {
    this.dispatch(async (state) => {
      state.isLoading = true;
      try {
        const res = await API.get('admin/companies', params);
        state.isLoading = false;
        state.data = res.data.data;
        state.meta = res.data.meta;
        state.isError = false;
        state.error = null;
      } catch (error) {
        state.isLoading = false;
        state.isError = true;
        state.error = error;
      }
    });
  }
}
