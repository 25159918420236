import { Store, useStore } from 'vuex';
import API from '../../api';
import { AccountStore } from './AccountStore';

export interface SettingsStoreState {
  isSubmiting: boolean;
  presets: {
    isLoading: boolean;
    data: any[];
  };
  bg: {
    isLoading: boolean;
    data: any[];
  };
  logos: {
    isLoading: boolean;
    data: any[];
  };
  banners: {
    isLoading: boolean;
    data: any[];
  };
}

export const SettingsStoreImpl = {
  namespaced: true,

  state: (): SettingsStoreState => ({
    isSubmiting: false,
    presets: {
      isLoading: false,
      data: []
    },
    bg: {
      isLoading: false,
      data: []
    },
    logos: {
      isLoading: false,
      data: []
    },
    banners: {
      isLoading: false,
      data: []
    }
  }),

  actions: {
    async updateState({ state }: { state: SettingsStoreState }, args: { doUpdate: (state: SettingsStoreState) => Promise<any> }) {
      return await args.doUpdate(state);
    }
  }
};

export class SettingsStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): SettingsStoreState {
    return this.store.state.settings;
  }

  public dispatch<T>(doUpdate: (state: SettingsStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('settings/updateState', { doUpdate });
  }

  public getSeparatedBackgrounds() {
    return {
      system: this.state.bg.data.filter(({ company_id }) => !company_id),
      own: this.state.bg.data.filter(({ company_id, has_room }) => company_id && !has_room)
    };
  }

  public async init(model, endpoint) {
    const accountStore = new AccountStore();

    this.dispatch(async (state) => {
      state[model].isLoading = true;
      try {
        const isChief = accountStore.state.data.role === 'chief';

        if (!isChief) {
          state[model].isLoading = false;
          state[model].data = [];

          return;
        }

        const methodPath = model === 'bg' ? 'company/background' : model === 'logos' ? 'company/logo' : 'company/banner';

        const res = await API.get(methodPath);
        state[model].isLoading = false;
        state[model].data = res.data;
      } catch (error) {
        console.error(error);
      }
    });
  }

  public async loadPresets() {
    this.dispatch(async (state) => {
      state.presets.isLoading = true;
      try {
        const res = await API.get('profile/presets');
        state.presets.isLoading = false;
        state.presets.data = res.data;
      } catch (error) {
        console.error(error);
      }
    });
  }

  public async saveChanges(model, endpoint, body, toast, t) {
    await this.dispatch(async (state) => {
      state.isSubmiting = true;
      state[model].isLoading = true;

      try {
        const methodPath = model === 'bg' ? 'company/background' : model === 'logos' ? 'company/logo' : 'company/banner';
        const res = await API.post(methodPath, body, 'formData');
        state[model].isLoading = false;
        state[model].data.push(res.data);
        state.isSubmiting = false;
      } catch (error) {
        const keys: string[] = Object.keys(error.response.data.errors);
        let errorMessage: string = '';

        for (const key of keys) {
          if (errorMessage != '') {
            errorMessage += ' ';
          }

          for (const errorItem of error.response.data.errors[key]) {
            if (errorMessage != '') {
              errorMessage += ' ';
            }

            errorMessage += errorItem;
          }
        }

        toast.add({ severity: 'error', summary: t('error'), detail: errorMessage, life: 3000 });

        state[model].isLoading = true;
        state.isSubmiting = false;
      }
    });
  }

  public async delete(model, endpoint, background) {
    await this.dispatch(async (state) => {
      state[model].isLoading = true;
      try {
        const methodPath = model === 'bg' ? 'company/background' : model === 'logos' ? 'company/logo' : 'company/banner';

        await API.delete(methodPath, { background });
        state[model].isLoading = false;
        state[model].data = state[model].data.filter(({ id }) => id !== background);
      } catch (error) {
        console.error(error);
      }
    });
  }
}
