import { errorHandling } from '@/utils';
import { saveAs } from 'file-saver';
import { Store, useStore } from 'vuex';
import API from '../../api';

export interface PhotosStoreState {
  isLoading: boolean;
  isError: boolean;
  data: {
    processed_photos_count?: any;
    photos: any;
    photos_ids?: any;
    datasets: any[];
  };
  error?: any;
}

export const PhotosStoreImpl = {
  namespaced: true,

  state: (): PhotosStoreState => ({
    isLoading: false,
    isError: false,
    data: {
      photos: [],
      datasets: []
    }
  }),

  actions: {
    async updateState({ state }: { state: PhotosStoreState }, args: { doUpdate: (state: PhotosStoreState) => Promise<any> }) {
      return await args.doUpdate(state);
    }
  }
};

export class PhotosStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): PhotosStoreState {
    return this.store.state.photos;
  }

  public dispatch<T>(doUpdate: (state: PhotosStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('photos/updateState', { doUpdate });
  }

  private async _init(state: PhotosStoreState, params: any) {
    try {
      state.isLoading = true;
      const res = await API.get(`admin/section/${params.sectionID}`, params);
      state.data = res.data;
      state.isLoading = false;
      state.isError = false;
      state.error = null;
    } catch (error) {
      state.isLoading = false;
      state.isError = true;
      state.error = error;
    }
  }

  public async init(params: any) {
    this.dispatch(async (state) => await this._init(state, params));
  }

  public async uploadPhoto(body, toast, t) {
    this.dispatch(async (state) => {
      try {
        state.isLoading = true;
        const res: any = await API.post('admin/photo/upload', body, 'formData');
        state.isLoading = false;
        state.isError = false;
        state.error = null;
        if (!res.message) {
          this._init(state, { sectionID: body.section_id });
          toast.add({ severity: 'success', summary: t('success'), life: 3000 });
        }
      } catch (error) {
        state.isLoading = false;
        state.isError = true;
        state.error = error;

        const { title, message } = errorHandling(error, t);
        toast.add({ severity: 'error', summary: title, detail: message, life: 3000 });
      }
    });
  }

  public async downloadPhoto(body, toast, t) {
    this.dispatch(async (state) => {
      try {
        const res = await API.post('admin/photo/download', body);
        saveAs(res.data.link);
      } catch (error) {
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
      }
    });
  }

  public async uploadDataset(body, toast, t) {
    this.dispatch(async (state) => {
      try {
        state.isLoading = true;
        const res: any = await API.post(`admin/neurodataset/upload`, body, 'formData');
        state.isLoading = false;
        state.isError = false;
        state.error = null;
        if (!res.message) {
          state.data.datasets.push(res.data);
          toast.add({ severity: 'success', summary: t('success'), life: 3000 });
        }
      } catch (error) {
        state.isLoading = false;
        state.isError = true;
        state.error = error;

        const { title, message } = errorHandling(error, t);
        toast.add({ severity: 'error', summary: title, detail: message, life: 3000 });
      }
    });
  }

  public async downloadDataset(sectionID, toast, t) {
    this.dispatch(async (state) => {
      try {
        const res = await API.get(`admin/neurodataset/download/${sectionID}`);
        saveAs(res.data.link);
      } catch (error) {
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
      }
    });
  }

  public async markProcessedPhoto(body, toast, t) {
    this.dispatch(async (state) => {
      try {
        await API.post('admin/photo/mark-processed', body);
        state.data.processed_photos_count = Object.values(body).filter((item) => item).length;
        toast.add({ severity: 'success', summary: t('success'), life: 3000 });
      } catch (error) {
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
      }
    });
  }

  public async deleteAlbum(body, toast, t, router) {
    this.dispatch(async (state) => {
      try {
        await API.delete(`admin/section/${body.sectionID}/delete`, body);
        toast.add({ severity: 'success', summary: t('success'), life: 3000 });
        router.push({ name: 'PhotoAlbums' });
      } catch (error) {
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
      }
    });
  }
}
