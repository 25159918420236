/* eslint-disable */
import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import Ripple from 'primevue/ripple';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import lazyPlugin from 'vue3-lazy';
import VueSimplebar from 'vue-simplebar';
import VueKonva from 'vue-konva';
import cookies from 'js-cookie';

import i18n from './i18n';
import App from './App.vue';
import router from './router';
import store from './store';

import 'vue-simplebar/dist/vue-simplebar.min.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import './assets/scss/theme/_core.scss';
import './assets/scss/fonts.scss';
import './assets/scss/main.scss';

const locale = cookies.get('locale') || 'en';

i18n.global.locale.value = locale;

createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(VueKonva)
  .use(ToastService)
  .use(ConfirmationService)
  .use(lazyPlugin, {})
  .use(VueSimplebar)
  .use(PrimeVue, { ripple: true })
  .directive('ripple', Ripple)
  .mount('#app');
