import { LocationQueryValue } from 'vue-router';
import { Store, useStore } from 'vuex';
import API from '../../api';

export interface AdminCarsStoreState {
  isLoading: boolean;
  isError: boolean;
  data?: {
    id: number;
    user_id: number;
    company_id: number;
    vin: string;
    vehicle_name: string;
    brand: string;
    model: string;
    body_type: string;
    fuel_type: string;
    hp: string;
    ccm: string;
    description: string;
    moderated: boolean;
    placeholder: string;
    preview: string;
    defect_state: 'ok' | 'defected';
    photo: {
      id: number;
      car_id: number;
      angle_id: number;
      url: string;
      processed_url: string;
      status: number;
      status_name: string;
      in_process_by_admin: number;
    };
    photos_count: number;
    angles_count: number;
    created_at: number;
    date: number;
    company: {
      id: number;
      user_id: number;
      title: string;
    };
  }[];
  premoderationFilter: string;
  selectedCompany: number;
  companies?: { id: number; title: string }[];
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    links: {
      url: string;
      label: string;
      active: boolean;
    }[];
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
  error: string;
}

export const AdminCarsStoreImpl = {
  namespaced: true,

  state: (): AdminCarsStoreState => ({
    isLoading: true,
    isError: false,
    data: null as any,
    premoderationFilter: 'all',
    selectedCompany: -1,
    companies: null as any,
    meta: {} as any,
    error: null as any
  }),

  actions: {
    async updateState({ state }: { state: AdminCarsStoreState }, args: { doUpdate: (state: AdminCarsStoreState) => Promise<any> }) {
      return await args.doUpdate(state);
    }
  }
};

export class AdminCarsStore {
  private store: Store<any>;

  public constructor(store: Store<any> | undefined = null) {
    this.store = store ? store : useStore();
  }

  public get state(): AdminCarsStoreState {
    return this.store.state.adminCars;
  }

  public dispatch<T>(doUpdate: (state: AdminCarsStoreState) => Promise<T>): Promise<T> {
    return this.store.dispatch('adminCars/updateState', { doUpdate });
  }

  public async init(args: { per_page?: number; page?: string | number | readonly LocationQueryValue[]; search?: string }): Promise<void> {
    this.dispatch(async (state) => {
      let params: any = args;

      state.isLoading = true;
      try {
        const companiesResponse = await API.get('admin/companies', { per_page: 200 });
        let companies = companiesResponse.data.data;

        if (state.premoderationFilter == 'premoderation') {
          companies = companies.filter((s) => s.premoderation);
        }

        companies = companies.map((s) => {
          return {
            id: s.id,
            user_id: s.user_id,
            title: s.title
          };
        });

        let companyIds = companies.map((s) => s.id);

        if (state.selectedCompany !== -1 && companyIds.indexOf(state.selectedCompany) === -1) {
          state.selectedCompany = -1;
        }

        if (state.selectedCompany !== -1) {
          companyIds = [state.selectedCompany];
        }

        // companies = [
        //   {
        //     id: -1,
        //     title: 'All companies'
        //   },
        //   ...companies
        // ];

        state.companies = companies;

        if (state.premoderationFilter === 'premoderation') {
          params = { ...params, moderation: 1 };
        }

        if (state.premoderationFilter === 'to_fix') {
          params = { ...params, per_page: 1000 };
        }

        if (state.selectedCompany !== -1) {
          params = { ...params, companyId: state.selectedCompany };
        }

        const carsResponse = await API.get('admin/cars', params);
        let cars = carsResponse.data.data;

        if (state.premoderationFilter === 'to_fix') {
          cars = cars.filter((s) => s.defect_state === 'defected');
        }

        for (const car of cars) {
          car.company = companies.filter((s) => s.user_id === car.user_id || s.id === car.company_id)[0];

          if (!car.company) {
            car.company = {
              id: 0,
              user_id: 0,
              title: 'Unknown'
            };
          }
        }

        state.isLoading = false;
        state.isError = false;
        state.companies = companies;
        state.data = cars;
        state.meta = { ...carsResponse.data.meta, to: cars.length, total: cars.length };
      } catch (error) {
        state.isLoading = false;
        state.isError = true;
        state.error = error;
      }
    });
  }
}
