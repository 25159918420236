export default {
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
  "save_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung speichern"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Adresse"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
  "replay_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort wiederholen"])},
  "something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier ging etwas schief. Bitte prüfe deine Eingabe!"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresse"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivieren"])},
  "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren"])},
  "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren"])},
  "password_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter stimmen nicht überein"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
  "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück"])},
  "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weiter"])},
  "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du sicher das du fortfahren möchtest?"])},
  "confirm_approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du sicher? Die Qualitätskontrolle wird abgeschlossen und das Fahrzeug freigegeben!"])},
  "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ergebnisse"])},
  "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine passenden Daten gefunden"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich!"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler!"])},
  "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff verweigert"])},
  "available_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Optionen"])},
  "bg_constructor": {
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
    "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade hoch..."])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["speichern"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["speichere..."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "wall_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung Wand:"])},
    "floor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung Boden:"])},
    "wall_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder Wand"])},
    "floor_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder Boden"])},
    "no_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Datei"])},
    "set_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei auswählen"])},
    "replace_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei austauschen"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herunterladen"])},
    "downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lade herunter..."])}
  },
  "scene_constructor": {
    "add_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotobox erstellen"])},
    "constructed_scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtuelle Fotoboxen & Hintergründe"])},
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZURÜCK"])},
    "select_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe wählen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["speichere..."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "upload_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu hochladen"])},
    "zero_layer_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfarbig"])},
    "own_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigener Hintergrund"])},
    "construct_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotobox erstellen"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu erstellen"])},
    "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotobox"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
    "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner"])}
  },
  "toast": {
    "success_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil wurde aktualisiert"])},
    "tokens_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben hinzugefügt "])},
    "vehicle_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeug hinzugefügt"])},
    "vehicle_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeug freigegeben"])},
    "photo_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild zur Bearbeitung gesendet"])},
    "photo_processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild erfolgreich bearbeitet"])},
    "update_car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeugdaten wurden aktualisiert"])},
    "upload_car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeugbild geladen"])},
    "no_files_to_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Dateien zum Herunterladen"])}
  },
  "page": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caiman App"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzer"])},
    "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionen"])},
    "transactions_by_companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionen Kunden"])},
    "creatingCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Kunde"])},
    "vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeuge"])},
    "creatingVehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Fahrzeug"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "creatingProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Nutzer"])},
    "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder"])},
    "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff verweigert"])}
  },
  "tabs": {
    "company_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzer"])},
    "angles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansichten"])},
    "settings_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis Einstellungen"])},
    "settings_bg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotoboxen"])},
    "settings_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logos"])},
    "settings_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner"])},
    "settings_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
  },
  "language": {
    "en_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "de_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "pt_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
    "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pt"])}
  },
  "status_bool": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])}
  },
  "profile": {
    "total_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben"])},
    "available_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben"])},
    "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Bild hochladen"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
    "add_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzer hinzufügen"])},
    "this_is_your_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das ist dein Benutzername"])},
    "password_min_length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Passwort muss aus mindestens", _interpolate(_named("number")), " Zeichen bestehen"])},
    "password_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter müssen übereinstimmen"])}
  },
  "login_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAIMAN Panel"])},
    "sub_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo! Schön dich wieder zu sehen."])},
    "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "contact_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support kontaktieren"])},
    "restore_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "remembered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt errinnere ich mich wieder!"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
    "restore_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Problem! Gib einfach deine E-mail Adresse ein"])},
    "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich kann diesen Benutzer nicht finden. Überprüfe deine Eingabe"])}
  },
  "company_page": {
    "add_new_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Kunde"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
    "contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben"])},
    "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzer"])},
    "available_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben"])},
    "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben"])},
    "add_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde hinzufügen"])},
    "additional_uploads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Uploads!"])},
    "attention_for_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung! Ein neues Passwort wird an die oben genannte E-Mail gesendet!"])},
    "add_new_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Nutzer"])},
    "can_work_on_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unbegrenztes Guthaben"])},
    "premoderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitätskontrolle"])},
    "show_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo anzeigen"])},
    "show_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner anzeigen"])},
    "allowed_angles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansichten auswählen und sortieren"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
  },
  "transactions_page": {
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erstellt am"])},
    "vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeug"])}
  },
  "vehicles_page": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
    "add_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Fahrzeug"])},
    "add_vehicle_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weiteres Fahrzeug"])},
    "vin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIN oder Bezeichnung"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hersteller"])},
    "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
    "contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "fuel_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraftstoff"])},
    "on_moderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitätskontrolle"])},
    "hp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PS"])},
    "ccm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCM"])},
    "vehicle_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeugbezeichnung"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wird bearbeitet"])},
    "savePhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild speichern"])},
    "replacePhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild ersetzten"])},
    "uploadPhotoProcessed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload processed"])},
    "replacePhotoProcessed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild wurde aktualisiert"])},
    "deleteVehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeug löschen"])},
    "body_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karosserie"])},
    "modal_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst den Hintergrund verschieben, um das Fahrzeug besser zu positionieren"])},
    "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild hochladen"])},
    "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Bezeichnung"])},
    "remove_extra_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra- Bild entfernen"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle auswählen"])},
    "with_original_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original Bilder"])},
    "with_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner"])},
    "extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
    "moderated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitätskontrolle"])},
    "not_moderated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["..."])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "for_premoderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitätskontrolle"])},
    "to_fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrekturen"])},
    "all_companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kunden"])},
    "find_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden finden"])},
    "select_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde wählen"])},
    "approve_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigeben"])},
    "vehicle_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist freigegeben"])},
    "show_to_fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler anzeigen"])},
    "hide_to_fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle anzeigen"])}
  },
  "scenes": {
    "add_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotobox erstellen"])}
  },
  "settings_page": {
    "tooltip_cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeug wird ausgeschnitten und in deiner fotobox platziert. Diese Funktion muss aktiviert sein"])},
    "tooltip_plate_blur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennzeichen wird duch unschärfe anonymisiert"])},
    "tooltip_plate_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennzeichen wird durch eine Grafik ersetzt"])},
    "default_crop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freistellung"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis"])},
    "photo_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotobox"])},
    "default_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Fotobox"])},
    "default_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Logo"])},
    "default_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Banner"])},
    "default_preset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Ansichten"])},
    "car_crop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freistellung für aktuelles Fahrzeug"])},
    "car_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotobox für aktuelles Fahrzeug"])},
    "car_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo für aktuelles Fahrzeug"])},
    "car_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner für aktuelles Fahrzeug"])},
    "car_preset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansichten für aktuelles Fahrzeug"])},
    "background_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintergrund"])},
    "logo_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position Logo"])},
    "license_plate_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennzeicheneinleger"])},
    "cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freistellen"])},
    "licence_plate_blur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennzeichen- Blur"])},
    "licence_plate_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennzeichen- Bild"])},
    "demo_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau der Bearbeitung"])},
    "add_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo hinzufügen"])},
    "export": {
      "ftp_host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ftp Host"])},
      "ftp_directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ftp Verzeichnis"])},
      "ftp_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ftp Benutzername"])},
      "ftp_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ftp Passwort"])},
      "directory_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzeichnis Name"])},
      "initial_file_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial Dateiname"])},
      "initial_file_index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial Datei Index"])},
      "file_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiformat"])},
      "send_photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder senden"])},
      "save_originals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Original Bildern"])},
      "store_as_zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablegen als zip"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportieren"])},
      "exporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exportiere..."])}
    },
    "title": {
      "preset_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinterlegte Hintergründe"])},
      "own_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigene Hintergründe"])},
      "constructed_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellte Fotoboxen"])},
      "scene_constructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellte Fotoboxen"])},
      "logotype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigene Logos"])},
      "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigene Banner"])}
    }
  },
  "photos_page": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "added_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added section*"])},
    "all_photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Bilder"])},
    "all_processed_photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bearbeiteten Bilder"])},
    "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder"])},
    "processed_photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitete Bilder"])},
    "markup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markup"])},
    "upload_markup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markup hochladen"])},
    "selected_to_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen zum herunterladen"])},
    "processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beareitet"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zähler"])}
  },
  "paginator": {
    "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zeige ", _interpolate(_named("count")), " von ", _interpolate(_named("total"))])},
    "separator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])}
  }
}